import React, {useState, useEffect} from 'react';
import SettingsWrapper from './SettingsWrapper'
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import Helper1, {notify, subStr, addUrlToPath} from '../../../utility/Helper1';
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import {authActions} from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import PinAuth from "../../../utility/customComponents/PinAuth";
import Countdown from 'react-countdown';
import copy from 'copy-to-clipboard';
import { AiOutlineCopy } from "react-icons/ai";

const GoogleAuth = () => {

    const dispatch = useDispatch();
    const userData = useSelector(userSelect);
    
    // const [withdrawalPin, setWithdrawalPin]   = useState('');
    // const [emailOTP, setEmailOTP]   = useState('');

    // const [securityObj, setSecurityObj] = useState({})
    // const [updatingPin, setUpdatingPin]   = useState(false);
    
    const [accessCode,  setAccessCode]      = useState('');
    const [loading,  setloading]      = useState('');


    
    const activateGoogleAuth = (e) => {

        e.preventDefault();
        setloading(true)

        dispatch(authActions.onLogin(`account/google-auth/activate`, {google2fa:accessCode}, response => {
            notify(response.msg, response.code)
            setloading(false);
            
        }),
        );

    }


    const deActivateGoogleAuth = (e) => {

        e.preventDefault();
        setloading(true)

        dispatch(authActions.onLogin(`account/google-auth/deactivate`, {google2fa:accessCode}, response => {
            notify(response.msg, response.code)
            setloading(false);

        }),
        );

    }
   


    return (
        <SettingsWrapper pageTitle={"Google Authentication"}>
            
            <div className="card-body">
                <div className="row">

                   


                    <div className="col-xxl-12 col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">{userData?.use_google_auth == 0?'Setup':'Reset'}  google authenticator</h4>
                            </div>
                            <div className="card-body">

                                    

                                <div className="">



                                        {userData?.use_google_auth == 0 && <div style={{margin: 10, padding: 10}}>


                                            {userData?.use_google_auth == 0 && <p className="text-left">
                                                
                                                <img src={userData?.google2faImage} />
                                                

                                                <h6 style={{}}>Setup Key</h6>

                                                <h4 className="text-info pointer" onClick={() => {copy(userData?.google2FASecret??''); notify('Copied', 1);}}>
                                                    <span>{userData?.google2FASecret}</span> 
                                                    <AiOutlineCopy color="#000" />
                                                </h4>


                                            </p>}



                                                <ul>

                                                    <li>
                                                        Download Google authenticator for android or Google authenticator for Apple
                                                    </li>
                                                    <li>
                                                        Scan the QRcode or enter the provided setup key above in your google authenticator app.
                                                    </li>
                                                    
                                                </ul>


                                            <div style={{}}>

                                                <br />

                                                <div style={{paddingBottom: 5}}>
                                                    <div class="single-input-wrap">
                                                        <span className="new-input-label" style={{}}>Enter Access Code</span>
                                                        <input autoComplete="new-password" placeholder="Access Code" onChange = {e => setAccessCode(e.target.value) } required="" className="form-field form-control" type="password"  style={{}}/>
                                                    </div>

                                                    
                                                    <br />

                                                    <button onClick={activateGoogleAuth} disabled={loading} type="submit" name="submit" className="btn btn-success btn-block">
                                                        Enable
                                                        <LoadingIcon color={"#fff"} active={loading} />
                                                    </button>


                                                </div>


                                            </div>

                                        </div>}




                                        {userData?.use_google_auth == 1 && <div style={{margin: 10, padding: 10}}>


                                            <p>
                                                <ol>
                                                    <li className="tex">
                                                        Google auth is active
                                                    </li>
                                                    <li>
                                                        Enter access code from google authicator app below to disable google auth.
                                                    </li>
                                                    <li>
                                                        Or contact support
                                                    </li>
                                                    
                                                </ol>
                                            </p>





                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                                <div className="" style={{maxHeight: 'auto', width: '90%'}}>
                                                    <div style={{paddingBottom: 5}}>

                                                        <div class="single-input-wrap">

                                                            <span className="new-input-label" style={{}}>Access Code</span>
                                                            <input autoComplete="new-password" placeholder="Access Code" onChange = {e => setAccessCode(e.target.value) } required="" className="form-field form-control" type="password"  style={{}}/>
                                                            
                                                        </div>


                                                        <br />

                                                        <button onClick={deActivateGoogleAuth} disabled={loading} type="submit" name="submit" className="btn btn-danger btn-block">
                                                            Reset
                                                            <LoadingIcon color={"#fff"} active={loading} />
                                                        </button>



                                                    </div>
                                                </div>
                                            </div>


                                        </div>}


                                </div>




                            </div>
                        </div>
                    </div>




                </div>
            </div>

        </SettingsWrapper>
    );
};

export default GoogleAuth;