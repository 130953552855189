import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import {useHistory} from 'react-router-dom';
import APIRequests from '../../../utility/APIRequests';
import Helper1, {notify} from '../../../utility/Helper1';
import { useSelector, useDispatch } from 'react-redux';
import {addUrlToPath} from '../../../utility/Helper1'
import { userSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import {authActions} from '../../../redux/actions';

// import AuthHolder from "./AuthHolder";
import {WebLink, SiteWide, Images} from "../../../utility/customComponents";
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import AuthHolder from "./AuthHolder";
import Countdown from 'react-countdown';
import ReCAPTCHA from "../../../utility/customComponents/ReCAPTCHA";


const Reset = () => {

    const [emailInput, setEmailInput] = useState('') 
    const [newPassword, setNewPassword] = useState('') 
    const [confirmNewPassword, setConfirmNewPassword] = useState('') 

    const site_settings = useSelector(allRemoteSettingsSelect);

    const [emailCode, setEmailCode] = useState('')
    const [requestOTPRetObj, setRequestOTPRetObj] = useState({})
    const [uiLevel, setUiLevel] = useState(0)

    const [fullPageLoading, setFullPageLoading]         = useState(false);
    
    const [loadingText, setLoadingText]         = useState("");
    const [loading, changeLoading]         = useState(false);

    const history                                       = useHistory();
    const [captcha, setCaptcha]                       = useState(null);

    const captchaHasChanged = (data) => {
        setCaptcha(data)
    }

    const requestOTP = async (e) => {
        
        e.preventDefault();

        if(loading){ return; }

        setFullPageLoading(true);
        changeLoading(true)

        let loadApi = new APIRequests;
        let getPost = await loadApi.runPost("account/reset_password_pin", {email: emailInput});

        if(getPost?.code == 1){
            setUiLevel(1)
            setRequestOTPRetObj(getPost?.data)
        }

        changeLoading(false)
        notify(getPost?.msg, getPost?.code)
        setFullPageLoading(false);

    }


   

    const changePassword = async (e) => {

        e.preventDefault();

        if(loading){ return; }
        
        if(!captcha && (Number(site_settings?.site_settings?.use_google_reCaptcha) == 1)){ notify('Invalid captcha response', 0); return; }

        if((newPassword != confirmNewPassword) || (newPassword == '')){
            notify("Passwords must match", 0)
            return;
        }

        changeLoading(true)
        setLoadingText("Please wait...")
        
        setFullPageLoading(true);

        let loadApi = new APIRequests;
        let getPost = await loadApi.runPost("account/reset_password", {captcha, email: emailInput, password:newPassword, passwordResetCode:emailCode});

        if(getPost?.code == 1){
            setUiLevel(0)
            notify("Password reset successful. You are being redirected to login page", 1)
            
            setTimeout(() => {
                history.replace('/signin');
            }, 3000)

        }else{
            notify(getPost?.msg ?? 'error', getPost?.code ?? 0)
        }

        changeLoading(false)
        setLoadingText("")

        // notify(getPost?.msg, getPost?.code)
        setFullPageLoading(false);


    }

    
    const handleReload = () => {
        window.location.reload();
    }

    return (
        <AuthHolder title={uiLevel === 0?'Reset Password':'Update your password'}>
            <div className="auth-form card">

                <div className="card-body">
                    <form name="myform" className="signin_validate row g-3">
                        
                        {uiLevel == 0 && <>
                            

                            <div className="col-12">
                                <label for="email-id">Email ID*</label>
                                <input id="email-id" onChange = {e => setEmailInput(e.target.value) } required type="email" className="form-control" placeholder="hello@example.com"/>
                            </div>

                            <div className="col-12"></div>

                            <div className="text-center">
                                <button onClick={requestOTP} type="submit" className="btn btn-primary btn-block">
                                    Reset Password

                                    <LoadingIcon color={"#fff"} active={loading} />

                                </button>
                            </div>


                        </>
                        }



                        {uiLevel == 1 && <>
                            
                            
                            <div className="col-12">
                                <label for="new-password-1">New Password*</label>
                                <input id="new-password-1" onChange={e => setNewPassword(e.target.value) } required type="password" className="form-control" placeholder="New Password"/>
                            </div>


                            <div className="col-12">
                                <label for="new-password-2">Confirm Password*</label>
                                <input id="new-password-2" onChange={e => setConfirmNewPassword(e.target.value) } required type="password" className="form-control" placeholder="Confirm Password"/>
                            </div>


                            <div className="col-12">
                                <label for="new-password-3">Emailed Token*</label>
                                <input id="new-password-3" onChange={e => setEmailCode(e.target.value) } required type="password" className="form-control" placeholder="Emailed Token"/>
                            </div>



                            <div className="col-12">

                                    <Countdown
                                        zeroPadTime={2}
                                        key={new Date(requestOTPRetObj?.passwordResetCodeTimer)}
                                        date={new Date(requestOTPRetObj?.passwordResetCodeTimer)}
                                        renderer={({ hours, minutes, seconds, completed }) => {
                                          if (completed) {
                                            // Render a completed state
                                            // timeUp();
                                            return <span className="design-text-for-click">Action Expired. <span style={{cursor: 'pointer', color: '#7589ea'}} onClick={handleReload}>Refresh</span> to try again</span>
                                            // 
                                          } else {
                                            // Render a countdown
                                            return <span>Expires in {minutes}:{seconds}</span>;
                                          }
                                        }}
                                    />


                            </div>


                            <div className="col-12">
                                <ReCAPTCHA 
                                    onChange={captchaHasChanged}
                                />
                            </div>

                            <div className="text-center">
                                <button onClick={changePassword} type="submit" className="btn btn-primary btn-block">
                                    Change Password

                                    <LoadingIcon color={"#fff"} active={loading} />

                                </button>
                            </div>


                        </>
                        }



                    </form>

                    <div className="new-account mt-3">
                        <p>Return to <Link className="text-primary" to={"/signin"}>Sign in</Link></p>
                    </div>
                </div>

            </div>
        </AuthHolder>
    );
};

export default Reset;