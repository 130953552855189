import React from 'react';
import WrapUpDashboard from '../../layout/WrapUpDashboard'
import SubMenu from './sub-menu';

const ProfileWrapper = (props) => {
    return (
        
        <WrapUpDashboard pageTitle={props?.pageTitle}>
            <div className="col-xxl-12 col-xl-12">
                <div className="page-title">
                    {/*<h4>{props.pageTitle}</h4>*/}
                </div>
                <div className="">
                    <div className="card-header">
                        <SubMenu pageTitle={props.pageTitle}/>
                    </div>
                    

                    {props.children}
 
                        
                </div>
            </div>

        
        </WrapUpDashboard>
    );
};

export default ProfileWrapper;