import React from 'react';
// import {show, hide} from "../customComponents/Notify";
import { store } from "../redux/store";
// var tinycolor = require("tinycolor2");
import {enqueueSnackbar } from 'notistack';



class Helper1{

  status = () => {
    return {0:'Awaiting approval', 1:'Active', 2:'Suspended'};
  }

}


export const capitalizeFirstLetter =(stro) => {
  if(!stro){ return ''; }
  let str = String(stro)
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)
}


export const notify = (msg, code) => {


    let message;

    switch (code) {
      case 0:
        message = 'error';
        break;
      case 1:
        message = 'success';
        break;
      default:
        message = 'info';
    }

    enqueueSnackbar(msg, { variant: message });
  
}


export const guessAdminRoute = () => {

  if (process.env.NODE_ENV === 'production') {

    
    const currentUrl = window.location.href;
    const urlWithoutSubdomain = currentUrl.replace(/^(?:https?:\/\/)?(?:[^@\/]+@)?/, '');
    const urlWithoutWww = urlWithoutSubdomain.replace(/^www./, '');
    const newlastUrl = urlWithoutWww.split("/")[0]

    let detectSubDomain =  newlastUrl.split(".")
    let newAdminBase = detectSubDomain.length > 2?`${detectSubDomain[detectSubDomain.length-2]}.${detectSubDomain[detectSubDomain.length-1]}`:newlastUrl

    return `https://admin.${newAdminBase}` //all live admin (API) address must look like admin.example.com, this app can be located on main or subdomain of same domain address with the admin


  }else{

    return "http://localhost/jonaOscarDec2023/public";

  }

}


export const addUrlToPath = (path) => {

  return guessAdminRoute() + '/' + path;

}


export const padNumber = (padThisNumber, length = 4, filler = 0, maximumNumber = 9999) => {
    
    let numberToPad = String(padThisNumber)

    if(numberToPad > maximumNumber){
        return numberToPad
    }

    let newpad = numberToPad?.padStart(length, filler)

    return newpad
}




export const displayTime = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    // timeZoneName: 'short'
  };
  
  return new Intl.DateTimeFormat('en-US', options).format(date);

}

export const formatTime = (dateString) =>  {
  const date = new Date(dateString);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}`;
}



export const formatDate = (date) => {
  var d = new Date(date);
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  var day = d.getDate();

  var currentYear = new Date().getFullYear();
  var formattedDate = padNumber(month, 2) + "/" + padNumber(day, 2);

  if (year !== currentYear) {
    formattedDate += "/" + year;
  }

  return formattedDate;
}


export const niceDate = (paramObj) => {
  const dateObj = new Date(paramObj);
  return dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1) + '-' + dateObj.getDate();
}


export const decimalCount = num => {
    const numStr = String(num)
    if(numStr.includes('.')){
        return numStr.split('.')[1].length;
    }

    return 0
}


const getMother = () => store.getState()?.settings?.settings?.plans;

export const money = (myAmount, currency, addObj={}) => {

  const motherObj = getMother()?.filter((item, index) => item.mother.type == Number(currency))
  const selectedMother = motherObj?.[0]?.mother ?? '';
  var nf = new Intl.NumberFormat("en-US", { minimumFractionDigits: addObj?.decimals ?? selectedMother?.decimals ?? 2 } );
  let syn = selectedMother?.symbol ?? '';
  // let syn = '';

  let amount = nf.format(myAmount);

  if(addObj?.removeSymbol){
    return amount
  }else if(selectedMother?.symbollocation == 0){
    return syn +  ' ' + amount
  }else if(selectedMother?.symbollocation == 1){
    return amount + ' ' + syn
  }

  return amount;
  

}


export const FormatOnly = (amount) => {
  var nf = new Intl.NumberFormat();
  return (nf.format(amount))
}



// export const customModalStyles = {
//       content: {
//             position: 'absolute',
//             bottom: 0,
//             border: '0px solid #ccc',
//             background: footer,
//             borderRadius: '30px 30px 0 0',
//             outline: 'none',
//             padding: '20px',
//             color: '#fff',
//             marginBottom: 0,
//             width: '100%',
//             transition: '0.5s',
//       },
//       overlay: {
//         position: 'fixed',
//         left: 0,
//         right: 0,
//         bottom: 0,
//         zIndex: 99999999,
//         backgroundColor: 'rgba(0, 0, 0, 0.6)',
//         padding: 0,
//         margin: 0,
//       }
//     };



// export const customNormalModalStyles = {
//   content: {
//     top: '40%',
//     left: '50%',
//     // right: '75%',
//     width: '400px',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     background: 'transparent',
//     zIndex: 999999,
    
//   },

//   overlay: {
//         position: 'fixed',
//         left: 0,
//         right: 0,
//         bottom: 0,
//         zIndex: 99999999,
//         background:  'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))',
//         padding: 0,
//         margin: 0,
//       }
// };




// export const balanceSettings = {
//         dots: false,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         autoplay: false,

//         responsive: [{
//             breakpoint: 600,
//             settings: {
//               slidesToShow: 1,
//               slidesToScroll: 1,
//               initialSlide: 1
//             }
//         }]
//     };





// export const currencySliderArray = (refToUse2, refToUse1, financeArray) => {
//         let arrowd;

//         arrowd = (financeArray ?? [1])?.length > 1 && <div style={{marginRight: 20}}>
//             <BsArrowLeftSquare onClick={() => 
//                     {   
//                         if(refToUse2){
//                             refToUse2.current.slickPrev(); 
//                         }
//                         if(refToUse1){
//                             refToUse1.current.slickPrev()
//                         }
//                     }
//                 } className="cursor-pointer text-info-1" size={20}/> {" "}
//             <BsArrowRightSquare onClick={() => 
//                 {
//                     if(refToUse2){
//                         refToUse2.current.slickNext(); 
//                     }
//                     if(refToUse1){
//                         refToUse1.current.slickNext()
//                     }

//                 } 

//             } className="cursor-pointer text-info-1" size={20}/>
//         </div>

//         return arrowd;
//     }


  export const moneyStatus = () => {
    return ['Pending', 'Processing', 'Confirmed', 'Cancelled'];
  }


export const usefulColors = (code) => {
    let array = ['danger', 'success', 'info', 'warning'];
    return array[code] ?? 'warning';
}


export const randColors = [
   "#46c7b1", "#ffbc02", "#262734", "#8644f6", "#375d80", "#79e4fe", "#baeaac", "#182bd2", "#20fd4d", "#6762e0", "#482e4a", "#fc5640", "#43fa05", "#3cde14", "#37a776", "#3d41ac", "#0dc19a", "#c5e011", "#230eaa", "#d24daa", "#9faa91", "#0f2c13", "#7a429c", "#47505f", "#FFA500", "#FFA500", "#ea9e70", "#63b598", "#c6e1e8", "#a48a9e", "#c6e1e8", "#648177" ,"#0d5ac1" , "#f205e6" ,"#1c0365" ,"#14a9ad" ,"#4ca2f9"
];


// export const hexToRgba = (hex) => {

//     var color = tinycolor(hex);
//     let conted = color.toRgb(); // { r: 255, g: 0, b: 0, a: 1 }

//   let r = conted?.r;
//   let g = conted?.g;
//   let b = conted?.b;
//   return `${r}, ${g}, ${b}`;
// }




export const byNextDate = (arr) => {
  let now = new Date();
  return arr?.filter(obj => new Date(obj.fixture_datetime) >= now).sort((a, b) => {
    return Math.abs(new Date(a.fixture_datetime) - now) -
           Math.abs(new Date(b.fixture_datetime) - now);
  });
}



export const byToDaysDate = (arr) => {

  var today = new Date();
  today.setHours(0, 0, 0, 0);

  var filteredArray = arr?.filter(function(obj) {
    var date = new Date(obj.fixture_datetime);
    return date >= today && date < new Date(today.getTime() + 86400000);
  });



  let now = new Date();
  return filteredArray?.filter(obj => new Date(obj.fixture_datetime) >= now).sort((a, b) => {
    return Math.abs(new Date(a.fixture_datetime) - now) -
           Math.abs(new Date(b.fixture_datetime) - now);
  });
}





export const fixtureStatuses = () => {
  return [
    ['CANC', 'Cancelled'],
    ['TBD', 'Time To Be Defined'],
    ['NS', 'Not Started'],
    ['1H', 'First Half, Kick Off'],
    ['HT', 'Halftime'],
    ['2H', 'Second Half, 2nd Half Started'],
    ['ET', 'Extra Time'],
    ['BT', 'Break Time'],
    ['P', 'Penalty In Progress'],
    ['SUSP', 'Match Suspended'],
    ['INT', 'Match Interrupted'],
    ['FT', 'Match Finished'],
    ['AET', 'Match Finished After Extra Time'],
    ['PEN', 'Match Finished After Penalty'],
    ['PST', 'Match Postponed'],
    ['CANC', 'Match Cancelled'],
    ['ABD', 'Match Abandoned'],
    ['AWD', 'Technical Loss'],
    ['WO', 'WalkOver'],
    ['LIVE', 'In Progress']
  ];
}



export const subStr = (stringToWorkOn, length = 10) => {

  let strReturned;
  let maxLength = length;
  let finalStr = stringToWorkOn ?? '';
  let string = finalStr.replace(/(\r\n|\n|\r)/gm, "").trim();

  if(string.length > maxLength){
    strReturned = string.substring(0, maxLength) + '...'
  }else{
    strReturned = string
  }

  return strReturned;
  
}

export const stripTags = (datag) => {
    // Use the nullish coalescing operator to provide a default value for data
    let data = datag ?? '';

    // Remove HTML tags and trim the string
    let datagg = data?.replace(/<\/?[^>]+(>|$)/g, '')?.trim();

    return datagg;
}




// export const base64ToBlob = (base64Image) => {

//   const mimeType = 'image/jpeg';
//   const byteCharacters = atob(base64Image);
//   const byteArrays = new Uint8Array(byteCharacters.length);
//   for (let i = 0; i < byteCharacters.length; i++) {
//     byteArrays[i] = byteCharacters.charCodeAt(i);
//   }
//   const blob = new Blob([byteArrays], { type: mimeType });
//   return URL.createObjectURL(blob);

// }




// export const base64ToBlob = (
//     dataURI: string,
//     contentType = 'image/jpeg',
//     sliceSize = 512,
//   ) => {
//     const byteCharacters = Buffer.from(dataURI, 'binary');
//     const byteArrays = [];

//     for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//       const slice = byteCharacters.subarray(offset, offset + sliceSize);

//       const byteArray = Uint8Array.prototype.slice.call(slice);
//       byteArrays.push(byteArray);
//     }

//     const blob = new Blob(byteArrays, { type: contentType });
//     return blob;
//   }


// export const base64ToBlob = async (base64Data, contentType = 'image/jpeg') => {
//   try {
//     // Fetch the data
//     const base64Response = await fetch(`data:${contentType};base64,${base64Data}`);

//     // Convert the response to a blob
//     const blob = await base64Response.blob();

//     return blob;
//   } catch (error) {
//     console.error('Error converting base64 to blob:', error);
//     // throw error;
//   }
// };
  

export const base64ToBlob = (dataURI, contentType = 'image/jpeg') => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this

    if(!dataURI){ return 'error' }

    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var bb = new Blob([ab], {type: contentType});
    return bb;
}


// export const base64ToBlob = (dataURI, contentType = 'image/jpeg') => {
//     dataURI = dataURI?.replace(/^data:/, '');

//     if(!dataURI){ return 'error' }

//     const type = dataURI.match(/image\/[^;]+/);
//     const base64 = dataURI.replace(/^[^,]+,/, '');
//     const arrayBuffer = new ArrayBuffer(base64.length);
//     const typedArray = new Uint8Array(arrayBuffer);

//     for (let i = 0; i < base64.length; i++) {
//         typedArray[i] = base64.charCodeAt(i);
//     }

//     return new Blob([arrayBuffer], {type: contentType});
// }


export const base64ToFile = (base64Data, filename = 'passport', contentType = 'image/jpeg') => {
    const blob = base64ToBlob(base64Data, contentType);

    const fileExtension = contentType.split('/').pop();

    // Append the file extension to the filename
    const fullFilename = `${filename}.${fileExtension}`;

    // Create a File object with the blob and set the filename
    const file = new File([blob], fullFilename, { type: contentType });

    return file;
};


export default Helper1;