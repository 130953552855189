import React, {useState, useEffect} from 'react';
 import PerfectScrollbar from 'react-perfect-scrollbar'
import WrapUpDashboard from '../layout/WrapUpDashboard'
import { Link } from 'react-router-dom';
import AreaChart from '../charts/area'
import DonutChart from '../charts/donut'
import PriceArea from '../charts/price-area'
import Slider from "react-slick";

import profile2 from '../../images/profile/2.png'

import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';
import SiteWide from "../../utility/customComponents/SiteWide";
import parse from 'html-react-parser';
import Helper1, {moneyStatus, notify, money, subStr, addUrlToPath} from '../../utility/Helper1';
import {WHOOWNS, OSCAR} from '../../utility/APIRequests';


import { stripHtml } from "string-strip-html";

import copy from 'copy-to-clipboard';
import Balance from "./Fragments/Balance";
import { Ticker, CopyrightStyles, TickerTape } from "react-ts-tradingview-widgets";
import PinAuth from "../../utility/customComponents/PinAuth";
import {authActions} from '../../redux/actions';
import LoadingIcon from "../../utility/customComponents/loader/LoadingIcon";
import TradingViewWidget from 'react-tradingview-widget';

import Ticker1 from "../../utility/customComponents/Ticker1";
import Ticker2 from "../../utility/customComponents/Ticker2";
import LoadingOverlay from 'react-loading-overlay';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

    // main slider
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 8.5,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 7.5,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 6.5,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },,
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 6.5,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 5.5,
                slidesToScroll: 1
              }
            }
        ]
    };




const Dashboard = () => {

    const dispatch = useDispatch();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);

    const [transferCurrencySelected, setTransferCurrencySelected]   = useState(finance?.[0]?.type ?? '');
    const [transferamount, setTransferAmount]   = useState('');
    const [transferIsLoading, setTransferIsLoading]   = useState(false);
    const [transferReceiverEmail, setTransferReceiverEmail]     = useState('');
    const [transferSecurityObj, setTransferSecurityObj] = useState({})
    const [transferUiShowMore, setTransferUiShowMore] = useState(false)

    const [refData, setRefData]     = useState([])
    const [refDataRaw, setRefDataRaw]     = useState([])
    const [loadingDownlines, setLoadingDownlines]     = useState(false)


    const avatarImg = ((userData?.image ?? null) != null)?addUrlToPath(userData?.image ?? ''):require('../../images/profile/user.png')

    const processTransfer = (e) => {
        
        e.preventDefault();

        if(transferIsLoading){ return; }

        setTransferIsLoading(true)

        let dataPayload = {amount: transferamount,  ...(transferSecurityObj ?? {}), email: transferReceiverEmail, currency: transferCurrencySelected}
        // let dataPayload = {amount: 1}

        dispatch(authActions.onLogin("fund/transfer/new", dataPayload, response => {

            if(response.code == 1){
                setTransferReceiverEmail('')
                setTransferAmount('')
            }

            setTransferIsLoading(false)
            notify(response.msg, response.code);  

        }))

    }



    const socials = [
            {
                title: 'facebook',
                uri: site_settings?.site_settings?.facebook,
                icon: 'icofont-facebook',
            },
            {
                title: 'instagram',
                uri: site_settings?.site_settings?.instagram,
                icon: 'icofont-instagram',
            },
            {
                title: 'twitter',
                uri: site_settings?.site_settings?.twitter,
                icon: 'icofont-twitter',
            },
            {
                title: 'telegram',
                uri: site_settings?.site_settings?.telegram,
                icon: 'icofont-telegram',
            },
            {
                title: 'whatsapp',
                uri: site_settings?.site_settings?.whatsapp,
                icon: 'icofont-whatsapp',
            },

        ]

    const textee = site_settings?.text_settings?.[0];

    const myRefCode = userData?.myrefid
    const myRefLink = site_settings?.site_settings?.user_dashboard_base_url+'/signup?ref='+ myRefCode
    const copyRefCode = (copythis) => {
        copy(copythis)
        notify('Copied', 1)
    }

    const newsB = (site_settings?.news?.[0]?.message ?? '<p></p>');
    const newsBody = subStr(stripHtml(newsB).result, 500)



    

    const styles: CopyrightStyles = {
        parent: {
          fontSize: "24px",
          color: "#fff",
          display: 'none'
        },
        link: {
          textDecoration: "line-trough",
          color: "#fff",
        },
        span: {
          color: "#fff",
        },
    };






    // load downlines things
    const loadDownLines = (myrefid, level) => {
        
        setLoadingDownlines(true)

        dispatch(authActions.onRefreshSystemData(`user/referral/${myrefid}`, 10, {}, response => {
            

            setLoadingDownlines(false)

            setRefDataRaw(response?.data)
            setRefData(response?.data?.referrals)

        }))
    }
    
    useEffect(() => {
        loadDownLines(userData?.myrefid, 1);
    }, [])


    const AllMyKYC = userData?.kyc;

    return (
        <WrapUpDashboard pageTitle={"My dashboard"}>

            {WHOOWNS === OSCAR && <div className="col-xxl-12 col-xl-12 col-lg-12">
                <div className="card">
                    <iframe src="https://bit2me.com/widget/crypto-carousel/v1" style={{display:'block', width:'100%', height:40, pointerEvents: 'none'}} frameborder="0"></iframe>
                </div>
            </div>}

            <div className="col-xxl-6 col-xl-6 col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div className="invite-content">
                            <h4>{parse(site_settings?.referral_settings?.[0]?.[`name_for_level`] ?? '')}</h4>
                            <p>
                                {parse(site_settings?.referral_settings?.[0]?.[`note_for_level`] ?? '')}
                            </p>

                            <div className="copy-link">
                                <form action="#">
                                    <div className="input-group">
                                        <input type="text" className="form-control"
                                            value={myRefLink} />
                                        <span onClick={() => copyRefCode(myRefLink)} className="pointer input-group-text">Copy</span>
                                    </div>
                                </form>
                            </div>

                            <div className="social-share-link">
                                {socials?.map((item, index) => {

                                    if(item.uri === ''){ return <></>; }
                                    
                                    return <a target={"_blank"} href={item.uri}><span><i className={item.icon}></i></span></a>
                                })}
                            </div>

                        </div>
                    </div>
                </div>
            </div>






            <div className="col-xxl-6 col-xl-6 col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div className="invite-content">

                            <h4>{parse(site_settings?.news?.[0]?.subject ?? '')}</h4>

                            {site_settings?.news?.length > 0 && <p>

                                {newsBody}
                                
                            </p>}

                            <Link to={"/news"} className="btn btn-primary">Read More</Link>
                        </div>
                    </div>

                </div>
            </div>




            <div className="col-xxl-4 col-xl-4 col-lg-4">
            <div className={`price-widget bg-btc`}>
                <>
                    <div style={{position: 'relative', top: 40, left: 20}} className="price-content lexify">
                        <div className="icon-title">
                            <span>Account Status</span>
                        </div>

                        <div className="lexify icon-title" style={{marginLeft: 5, paddingLeft: 0, isplay: 'flex', lignItems: 'flex-atart'}}>
                            <h6 className="text-white" style={{margin: 0, marginTop: 10}}>
                                {moneyStatus()[AllMyKYC?.[0]?.status]}
                            </h6>
                        </div>
                    </div>
                    <PriceArea />
                </>
        
            </div>
            </div>

            <div className="col-xxl-4 col-xl-4 col-lg-4">
            <div className={`price-widget bg-usdt`}>
                <>
                    <div style={{position: 'relative', top: 40, left: 20}} className="price-content lexify">
                        <div className="icon-title">
                            <span>Fund Status</span>
                        </div>

                        <div className="lexify icon-title" style={{marginLeft: 5, paddingLeft: 0, isplay: 'flex', lignItems: 'flex-atart'}}>
                            <h6 className="text-white" style={{margin: 0, marginTop: 10}}>{userData?.demoAccount == 1?'Demo':'Active'}</h6>
                        </div>
                    </div>
                    <PriceArea />
                </>
        
            </div>
            </div>

            <div className="col-xxl-4 col-xl-4 col-lg-4">
            <div className={`price-widget bg-eth`}>
                <>
                    <div style={{position: 'relative', top: 40, left: 20}} className="price-content lexify">
                        <div className="icon-title">
                            <span>Total Referral</span>
                        </div>

                        <div className="lexify icon-title" style={{marginLeft: 5, paddingLeft: 0, isplay: 'flex', lignItems: 'flex-atart'}}>
                            <h6 className="text-white" style={{margin: 0, marginTop: 10}}>
                                {refData['level1']?.length ?? 0}
                            </h6>
                        </div>
                    </div>
                    <PriceArea />
                </>
        
            </div>
            </div>

            {1>2 && <>

                <div className="col-xxl-3 col-xl-3 col-lg-6">
                    <div className="price-widget bg-btc">
                        <Link to={"/price-details"}>
                            <div className="price-content">
                                <div className="icon-title">
                                    <i className="cc BTC-alt"></i>
                                    <span>Bitcoin</span>
                                </div>
                                <h5>$ 11,785.10</h5>
                            </div>
                            <PriceArea />
                        </Link>
                    </div>
                </div>

                <div className="col-xxl-3 col-xl-3 col-lg-6">
                    <div className="price-widget bg-eth">
                        <Link to={"/price-details"}>
                            <div className="price-content">
                                <div className="icon-title">
                                    <i className="cc ETH-alt"></i>
                                    <span>Ethereum</span>
                                </div>
                                <h5>$ 11,785.10</h5>
                            </div>
                            <PriceArea />
                        </Link>
                    </div>
                </div>

                <div className="col-xxl-3 col-xl-3 col-lg-6">
                    <div className="price-widget bg-usdt">
                        <Link to={"/price-details"}>
                            <div className="price-content">
                                <div className="icon-title">
                                    <i className="cc USDT-alt"></i>
                                    <span>Tether</span>
                                </div>
                                <h5>$ 11,785.10</h5>
                            </div>
                            <PriceArea />
                        </Link>
                    </div>
                </div>

                <div className="col-xxl-3 col-xl-3 col-lg-6">
                    <div className="price-widget bg-usdt">
                        <Link to={"/price-details"}>
                            <div className="rice-content" style={{position: 'relative', left: 20, top: 20}}>
                                <div className="icon-title h4">
                                    <i className="cc USDT-alt"></i>
                                    <span>Tether</span>
                                </div>
                                <h5>$ 11,785.10</h5>
                            </div>
                            <PriceArea />
                        </Link>
                    </div>
                </div>

            </>}






            <div className="card" style={{padding: 10}}>
                <div className="card-body">
                    <Slider className="" arrows={false} {...settings}>
                        {finance?.map((item, index) => (
                            <div>
                                    <img style={{}} height="40" width="40" src={item?.image} />
                            </div>)
                        )}
                    </Slider>
                </div>
            </div>




            <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="card welcome-profile"  style={{height: 400}}>
                    <div className="card-body" style={{backgroundImage: `url(${addUrlToPath(site_settings?.image_settings?.[0]?.image8)})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPositionFaked: -170}}>
                        <div className="flexify">
                            <img src={avatarImg} alt="avatar" />
                            <div style={{flex: 1, marginLeft:20}}>
                                <h4>Welcome, {userData?.username}</h4>
                                <p>Quick menu to help you stay updated with your account Information</p>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <Link to={"/settings-payment-method"}>
                                    <span className="verified"><i className="icofont-check-alt"></i></span>
                                Payment Information
                            </Link>
                            </li>
                            <li>
                                <Link to={"/trade-online"}>
                                    <span className="verified"><i className="icofont-check-alt"></i></span>
                                Available {userData?.demoAccount == 1?'demo':''} trades
                            </Link>
                            </li>
                            
                            {userData?.demoAccount == 0 && <li>
                                <Link to={"/fund"}>
                                    <span className="verified"><i className="icofont-check-alt"></i></span>
                                    Deposit money
                                </Link>
                            
                            </li>}
                            <li>
                                <Link to={"/settings-profile"}>
                                    <span className="verified"><i className="icofont-check-alt"></i></span>
                                    My profile
                                </Link>

                            </li>

                            {userData?.demoAccount == 1 && <li></li>}

                        </ul>
                    </div>
                </div>
            </div>

            <div className="col-xxl-6 col-xl-6 col-lg-6">
                

 
                <LoadingOverlay
                  active={Number(userData?.demoAccount) === 1}
                  text='Demo account'
                  >

                    <div className="card" style={{minHeight: 400}}>
                        <div className="card-header">
                            <h4 className="card-title">Quick Transfer </h4>
                        </div>
                        <div className="card-body">
                            <form method="post" className="currency_validate trade-form row g-3">
                                

                                <div className="col-12">
                                    <label className="form-label">Source</label>
                                    <div className="input-group">
                                        <select className="form-select" value={transferCurrencySelected} onChange={e => setTransferCurrencySelected(e.target.value)}>
                                            
                                            {finance?.map((item, index) => {
                                                
                                                return <option value={item?.type}>{item?.name} {`${money(item.balance, transferCurrencySelected)}`}</option>
                                            
                                            })}

                                        </select>
                                    </div>
                                </div>


                                <div className="col-12">
                                    <label className="form-label">Amount</label>
                                    <div className="input-group">

                                        <input type="text" value={transferamount} onChange={el => setTransferAmount(el.target.value)} className="form-control"
                                            placeholder="Transfer amount" />

                                    </div>
                                </div>

                                <div className="col-12">
                                    <label className="form-label">Recipient Email ID</label>
                                    <div className="input-group">
                                        
                                        <input type="email" value={transferReceiverEmail} onChange={el => setTransferReceiverEmail(el.target.value)} className="form-control"
                                            placeholder="email ID" />


                                    </div>
                                </div>

                                {transferUiShowMore && <>

                                    <PinAuth resultingObjMethod={setTransferSecurityObj} userData={userData} />

                                    <div className="col-12">
                                        <button onClick={e => {
                                                    window.confirm('Transfer is irreversible. Click OK to confirm transfer.') && processTransfer(e)
                                                }
                                            } 
                                            type="submit" className="btn btn-success btn-block">
                                            Transfer Now

                                            <LoadingIcon color={"#fff"} active={transferIsLoading} />

                                        </button>
                                    </div>

                                </>}


                                {!transferUiShowMore && <p className="mb-0"><a className="pointer" onClick={() => setTransferUiShowMore(true)} to={"#"}>Show more</a></p>}
                                {transferUiShowMore && <p className="mb-0"><a className="pointer" onClick={() => setTransferUiShowMore(false)} to={"#"}>Show less</a></p>}


                            </form>

                        </div>
                    </div>

                </LoadingOverlay>
            </div>

            <div className="col-xxl-12">
                <Balance />
            </div>


             {/*<div className="col-xxl-12 col-xl-12 col-lg-12" style={{marginBottom: 10}}>
                <Ticker key={"gvhbjn"} colorTheme="light" toolbar_bg="red" copyrightStyles={styles} />
            </div>*/}

            {1>2 && <div className="col-xxl-12 col-xl-12">
                <div className="card home-chart">
                    <div className="card-header">
                        <h4 className="card-title home-chart">Analytics</h4>
                        <select className="form-select" name="report-type" id="report-select">
                            <option value="1">Bitcoin</option>
                            <option value="2">Litecoin</option>
                        </select>

                    </div>
                    <div className="card-body">
                        <div className=" home-chart-height">
                            <AreaChart/>
                            <div className="row">
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="chart-price-value">
                                        <span>24hr Volume</span>
                                        <h5>$236,368.00</h5>
                                    </div>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="chart-price-value">
                                        <span>Marketcap</span>
                                        <h5>$236.025B USD</h5>
                                    </div>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="chart-price-value">
                                        <span>24hr Volume</span>
                                        <h5>56.3 BTC</h5>
                                    </div>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="chart-price-value">
                                        <span>All Time High</span>
                                        <h5>$236,368.00</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}






            {/*<div className="col-xxl-6 col-xl-6">
                <div className="card">
                    <div className="card-body">
                        <Slider className="" arrows={false} {...settings}>
                            {site_settings?.slider_settings?.map((item, index) => (

                                    <a target="_blank" href={item?.linkWhenClicked}>
                                        <img style={{width: '100%'}} src={item?.image} />
                                    </a>

                                    )
                            )}
                        </Slider>
                    </div>
                </div>
            </div>*/}



            {/*<div className="col-xxl-6 col-xl-6">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Transaction</h4>

                    </div>
                    <div className="card-body">
                        <div className="table-responsive transaction-table">
                            <table className="table table-striped responsive-table">
                                <thead>
                                    <tr>
                                        <th>Ledger ID</th>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>Currency</th>
                                        <th>Amount</th>
                                        <th>Fee</th>
                                        <th>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>523640</td>
                                        <td>January 15</td>
                                        <td>
                                            <span className="danger-arrow"><i className="icofont-arrow-down"></i>
                                            Sell</span>
                                        </td>
                                        <td className="coin-name">
                                            <i className="cc BTC"></i> Bitcoin
                                    </td>
                                        <td className="text-danger">-0.000242 BTC</td>
                                        <td>0.02%</td>
                                        <td><strong>0.25484 BTC</strong></td>
                                    </tr>
                                    <tr>
                                        <td>523640</td>
                                        <td>January 15</td>
                                        <td><span className="success-arrow"><i
                                            className="icofont-arrow-up"></i>Buy</span>
                                        </td>
                                        <td className="coin-name">
                                            <i className="cc LTC"></i> Litecoin
                                    </td>
                                        <td className="text-success">-0.000242 BTC</td>
                                        <td>0.02%</td>
                                        <td><strong> 0.25484 LTC</strong></td>
                                    </tr>
                                    <tr>
                                        <td>523640</td>
                                        <td>January 15</td>
                                        <td><span className="success-arrow"><i
                                            className="icofont-arrow-up"></i>Buy</span>
                                        </td>
                                        <td className="coin-name">
                                            <i className="cc XRP"></i> Ripple
                                    </td>
                                        <td className="text-success">-0.000242 BTC</td>
                                        <td>0.02%</td>
                                        <td><strong> 0.25484 LTC</strong></td>
                                    </tr>
                                    <tr>
                                        <td>523640</td>
                                        <td>January 15</td>
                                        <td><span className="success-arrow"><i
                                            className="icofont-arrow-up"></i>Buy</span>
                                        </td>
                                        <td className="coin-name">
                                            <i className="cc DASH"></i> Dash
                                    </td>
                                        <td className="text-success">-0.000242 BTC</td>
                                        <td>0.02%</td>
                                        <td><strong> 0.25484 LTC</strong></td>
                                    </tr>
                                    <tr>
                                        <td>523640</td>
                                        <td>January 15</td>
                                        <td><span className="success-arrow"><i
                                            className="icofont-arrow-up"></i>Buy</span>
                                        </td>
                                        <td className="coin-name">
                                            <i className="cc LTC"></i> Litecoin
                                    </td>
                                        <td className="text-success">-0.000242 BTC</td>
                                        <td>0.02%</td>
                                        <td><strong> 0.25484 LTC</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>*/}

                  
        </WrapUpDashboard>
    );
};

export default Dashboard;