import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import {useHistory} from 'react-router-dom';
import APIRequests from '../../../utility/APIRequests';
import Helper1, {notify} from '../../../utility/Helper1';
import { useSelector, useDispatch } from 'react-redux';
import {addUrlToPath} from '../../../utility/Helper1'
import { userSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import {authActions} from '../../../redux/actions';

// import AuthHolder from "./AuthHolder";
import {WebLink, SiteWide, Images} from "../../../utility/customComponents";
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import ReCAPTCHA from "../../../utility/customComponents/ReCAPTCHA";

import AuthHolder from "./AuthHolder";


const Signin = () => {

    const dispatch = useDispatch();
    const users = useSelector(userSelect);

    const [email, setEmail]                             = useState('');

    
    const [password, setPassword]                       = useState('');
    const [loading, changeLoading]                      = useState(false);
    const [loadingText, setLoadingText]                      = useState("");
    const [notificationMessage, setNotificationMessage] = useState('');
    const [fullPageLoading, setFullPageLoading]         = useState(false);
    const history                                       = useHistory();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);




    const [captcha, setCaptcha]                       = useState(null);

    const captchaHasChanged = (data) => {
        setCaptcha(data)
    }

    const doLogin = async (e) => {

        e.preventDefault();

        if(!captcha && (Number(site_settings?.site_settings?.use_google_reCaptcha) == 1)){ notify('Invalid captcha response', 0); return; }

        setFullPageLoading(true);
        if(loading){ return; }

        changeLoading(true)
        setLoadingText("Authenticating...")

        dispatch(authActions.onLogin("account/login", {username:email, captcha, password:password}, response => {


            if(response.code !== 0){

                if(response.code == 1){
                    history.replace('/');
                }else if(response.code === 2){
                    history.replace('/verify-user');
                }

            }
            
            notify(response.msg, response.code)
            changeLoading(false)
            setLoadingText("")

            setFullPageLoading(false);
        
        }),
    );

        
    };


    
    let userLoginStyle = site_settings?.operational_settings?.userLoginStyle
    let userLoginStyleObj = [
        {
            inputType: 'email',
            placeholder: 'Email ID',
        },
        {
            inputType: 'text',
            placeholder: 'Username',
        }

    ]

    return (
        <AuthHolder title={`Sign in to ${site_settings?.site_settings?.sitename}`}>
            <div className="auth-form card">

                <div className="card-body">
                    <form name="myform" className="signin_validate row g-3">
                        <div className="col-12">
                            <input onChange = {e => setEmail(e.target.value) } required type={userLoginStyleObj?.[userLoginStyle]?.inputType} className="form-control" placeholder={userLoginStyleObj?.[userLoginStyle]?.placeholder}
                                name="email"/>
                        </div>
                        <div className="col-12">
                            <input onChange={e => setPassword(e.target.value) } required type="password" className="form-control" placeholder="Password"
                                name="password"/>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
                                <label className="form-check-label" for="flexSwitchCheckDefault">Remember
                                    me</label>
                            </div>
                        </div>
                        <div className="col-6 text-right">
                            <Link to={"/reset"}>Forgot Password?</Link>
                        </div>


                        <div className="col-12">
                            <ReCAPTCHA 
                                onChange={captchaHasChanged}
                            />
                        </div>


                        <div className="text-center">
                            <button onClick={doLogin} type="submit" className="btn btn-primary btn-block">
                                Sign in

                                <LoadingIcon color={"#fff"} active={loading} />

                            </button>
                        </div>
                    </form>
                    <p className="mt-3 mb-0">Don't have an account? <Link className="text-primary"
                            to={"/signup"}>Sign up</Link></p>
                </div>

            </div>
        </AuthHolder>
    );
};

export default Signin;