import React, {useState, useEffect} from 'react';
import MultiStep from 'react-multistep'
import { ViewFlow, Step } from 'react-view-flow'



import { Link } from 'react-router-dom';
import {depositActions} from '../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect, allWithdrawalSelect } from '../../../redux/selectors/index';
import Helper1, {notify, subStr, addUrlToPath, moneyStatus, usefulColors, money} from '../../../utility/Helper1';
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import copy from 'copy-to-clipboard';
import { AiOutlineWallet, AiFillDelete, AiOutlineCopy, AiFillInfoCircle, AiOutlineCheck, AiOutlineCloudUpload } from "react-icons/ai";
import Countdown from 'react-countdown';
import WrapUpDashboard from '../../layout/WrapUpDashboard'
import {loanActions} from '../../../redux/actions';
import QRCode from "react-qr-code";
import PinAuth from "../../../utility/customComponents/PinAuth";
import NoData from "../../../utility/customComponents/NoData";
import LoanSubMenu from "./Loan-sub-menu";
import  "./pricing-style/pricing-table.css";
import { useHistory } from "react-router-dom";




const loanPurposes = [
  { id: 1, name: 'Wedding' },
  { id: 2, name: 'Boat' },
  { id: 3, name: 'Business' },
  { id: 4, name: 'Green Loan' },
  { id: 5, name: 'Household Expenses' },
  { id: 6, name: 'Medical and Dental' },
  { id: 7, name: 'Moving and Relocation' },
  { id: 8, name: 'Taxes' },
  { id: 9, name: 'Debt Consolidation' },
  { id: 10, name: 'Credit Card Refinance' },
  { id: 11, name: 'Home Improvement' },
  { id: 12, name: 'Special Occasion' },
  { id: 13, name: 'Auto Refinance' },
  { id: 14, name: 'Auto' },
  { id: 15, name: 'Baby' },
];


const creditScoreRanges = [
  {id: 0, title: 'Excellent', min: 720, max: Infinity },
  {id: 1, title: 'Good', min: 660, max: 719 },
  {id: 2, title: 'Fair', min: 620, max: 659 },
  {id: 3, title: 'Poor', min: 300, max: 619 },
  {id: 4, title: 'Limited/No History', min: 0, max: 0 }
];



const homeOwnershiptitles = [
  { id: 0, title: 'Rent your home' },
  { id: 0, title: 'Own Your home' },
  { id: 0, title: 'Own with Mortgage' },
];


const educationLevels = [
  { id: 1, title: "High School Diploma" },
  { id: 2, title: "Associate's Degree" },
  { id: 3, title: "Bachelor's Degree" },
  { id: 4, title: "Master's Degree" },
  { id: 5, title: "Other Graduate Degree" },
  { id: 6, title: "Other" },
];



const Wallet = () => {

    const history = useHistory();
    const finance = useSelector(homeScreenStatSelect);
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const op_se = site_settings.operational_settings
    const loadHistoryData = useSelector(allWithdrawalSelect);


    const [amount, setAmount]   = useState(null);
    const [loanDuration, setLoanDuration]   = useState(null);
    const [loanPurpose, setLoanPurpose]   = useState('');
    const [creditScore, setCreditScore]   = useState('');
    const [homeOwnership, setHomeOwnership]   = useState('');
    const [educationalLevel, setEducationalLevel]   = useState('');


    const allMotherPlans = site_settings?.plans
    const [currencySelected, setCurrencySelected]   = useState(allMotherPlans?.[0]?.mother); //finance?.[0]

    const [investingIsActive, setInvestingIsActive]   = useState(false);


    const dispatch = useDispatch();

    const updateCurrencySelected = (typeOfSelected) => {
        let selected = allMotherPlans?.filter((ite, inde) => ite.mother.type == typeOfSelected)?.[0] ?? []

        setCurrencySelected(selected?.mother ?? {})

    }

    const updateLoanPurpose = (purpose) => {

        setLoanPurpose(purpose)

    }


    const updateCreditScore = (scoreObj) => {
        setCreditScore(scoreObj)
    }

    const updateHomeOwnership = (title) => {
        setHomeOwnership(title)
    }

    const updateEducationalLevel = (title) => {
        setEducationalLevel(title)
    }


    


    const stepOne = () => {

        return <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Preferred Currency</h4>
                    </div>
                    <div className="card-body">

                        <div className="row">

                            <div className={`col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12`}>

                                <select style={{borderWidth: 1, borderColor: '#33c3f0'}} onChange={e => updateCurrencySelected(e.target.value)} className="form-select">
                                    
                                    {allMotherPlans?.map((item, index) => <>
                                        <option key={`ghgvhbj${index}`} value={item.mother.type}>{item?.mother.abbr}</option>
                                    </>)}

                                </select>

                            </div>


                        </div>

                    </div>





                    <div className="card-header">
                        <h4 className="card-title">Purpose of loan</h4>
                    </div>
                    <div className="card-body">

                        <div className="row">

                            <div className={`col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12`}>

                                <select style={{borderWidth: 1, borderColor: '#33c3f0'}} value={loanPurpose} onChange={e => updateLoanPurpose(e.target.value)} className="form-select">
                                    
                                    <option hidden>Select purpose</option>

                                    {loanPurposes?.map((item, index) => <>
                                        <option key={`purpose${index}`} value={item.name}>{item?.name}</option>
                                    </>)}
                                </select>

                            </div>


                        </div>

                    </div>





                    <div className="card-header">
                        <h4 className="card-title">Home ownership</h4>
                    </div>
                    <div className="card-body">

                        <div className="row">

                            <div className={`col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12`}>

                                <select value={homeOwnership} style={{borderWidth: 1, borderColor: '#33c3f0'}} onChange={e => updateHomeOwnership(e.target.value)} className="form-select">
                                    
                                    <option hidden>Select ownership</option>

                                    {homeOwnershiptitles?.map((item, index) => <>
                                        <option key={`homeownership${index}`} value={item.title}>{item?.title}</option>
                                    </>)}
                                </select>

                            </div>


                        </div>

                    </div>




                    <div className="card-header">
                        <h4 className="card-title">Educational level</h4>
                    </div>
                    <div className="card-body">

                        <div className="row">

                            <div className={`col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12`}>

                                <select value={educationalLevel} style={{borderWidth: 1, borderColor: '#33c3f0'}} onChange={e => updateEducationalLevel(e.target.value)} className="form-select">
                                    
                                    <option hidden>Select level</option>

                                    {educationLevels?.map((item, index) => <>
                                        <option key={`edulevel${index}`} value={item.title}>{item?.title}</option>
                                    </>)}
                                </select>

                            </div>


                        </div>

                    </div>

                </div>
    }


    const stepTwo = () => {


            return <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Credit Score</h4>
                    </div>
                    <div className="card-body">

                        <div className="row">

                            {creditScoreRanges?.map((item, index) => <div onClick={() => updateCreditScore(item)} className={`col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6`}>
                                <div className={`flexify pointer chart-stat ${creditScore?.id == index?'active-tenure':''}`}>
                                    <div>
                                        <h4>{item?.title}</h4>
                                        <p className="mb-1">Min: {item?.min}</p>
                                        <p className="mb-1">Max: {item?.max}</p>
                                    </div>
                                    {creditScore?.id == index && <AiOutlineCheck size={20} />}
                                </div>
                            </div>)}

                        </div>

                    </div>

                    

                </div>
    }



    


    const submitLoanRequest = (e) => {

        e.preventDefault();

        if(investingIsActive){ return; }


        if(isNaN(amount) || (amount <= 0)){
            notify('Enter amount', 0);
            return;
        }


        const payLoad = {amount, currency:currencySelected?.type, loanDuration, loanPurpose, creditScore: creditScore?.title, homeOwnership, educationalLevel};

        
        setInvestingIsActive(true)

        dispatch(loanActions.onPOST(payLoad, "loan/new", response => {
                setInvestingIsActive(false)
                notify(response.msg, response.code);
                if(response.code == 1){
                    history.push('/loan-online')
                }
                
            }),
        )

    }

    let weeklInterest = (Number(currencySelected?.loan_interest_per_week ?? 0)/100) * Number(amount ?? 0)
    let interestAmount =  (weeklInterest * Number(loanDuration ?? 0)) + Number(amount ?? 0)
    let moneyAmount = money(interestAmount, currencySelected?.type, {removeSymbol: true})

    const stepThree = () => {

        return <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Process</h4>
                    </div>
                    <div className="card-body">

                        <form onSubmit = {submitLoanRequest} className="currency_validate trade-form row g-3">


                            <h4>{currencySelected.loan_note_headline}</h4>
                            <p className="mb-0">{currencySelected.loan_note_body}</p>

                            <div className="col-12">
                                <label className="form-label">Enter amount</label>
                                <div className="input-group">
                                    
                                    <input autoComplete="new-password" type="text" onChange={(e) => setAmount(e.target.value)} value={amount} name="currency_amount" className="form-control"
                                        placeholder="0.00" />

                                </div>
                            </div>

                            <div className="col-12">
                                <label className="form-label">Loan Duration in weeks</label>
                                <div className="input-group">
                                    
                                    <input autoComplete="new-password" type="text" onChange={(e) => setLoanDuration(e.target.value)} value={loanDuration} className="form-control"
                                        placeholder="Duration (Weeks)" />

                                </div>
                            </div>


                            <div className="col-12">
                                <label className="form-label">Repayment Calculator</label>
                                <p className="mb-0">Amount to be repaid after {loanDuration ?? 0} {(loanDuration ?? 0)> 1 ? 'weeks':'week'}</p>
                                <div className="input-group flexify" style={{borderWidth: 1, borderColor: '#f7f8fa', padding: 20}}>
                                    
                                    <img style={{width: 50}} src={require('../../../images/calculator.png')} />

                                    <h2 style={{flex: 1, marginLeft: 12}}>{moneyAmount}</h2>

                                </div>
                            </div>




                            <button disabled={investingIsActive} type="submit" name="submit" className="btn btn-success btn-block">
                                Submit
                                <LoadingIcon color={"#fff"} active={investingIsActive} />
                            </button>

                        </form>

                    </div>
                </div>
    }





    const steps = [
              {title: 'Prerequisite', component: stepOne()},
              {title: 'Loan Score', component: stepTwo()},
              {title: 'Commit', component: stepThree()},

            ];




    return (<WrapUpDashboard pageTitle={"New Trade"}>

        <div className="card-header">
            <LoanSubMenu pageTitle={'Loan Application'}/>
        </div>


        <div className="row">

           



            <div className="col-xxl-12 col-xl-12 col-lg-12">

                <MultiStep showNavigation={true} steps={steps} activeStep={0} prevButton={{title: 'Back', style: { background: 'transparent', color: '#33c3f0', borderWidth: 1, borderColor: '#33c3f0' }}} nextButton={{title: 'Next', style: { background: 'transparent', color: '#33c3f0', borderWidth: 1, borderColor: '#33c3f0', marginLeft: 10 }}} />

                <br/><br/>

            </div>


        </div>

    </WrapUpDashboard>);
};

export default Wallet;