import React, {useState, useRef, useCallback, useEffect} from 'react';
import ProfileWrapper from './ProfileWrapper'
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import Helper1, {notify, subStr, addUrlToPath, base64ToBlob, base64ToFile, moneyStatus, usefulColors} from '../../../utility/Helper1';
import APIRequests from '../../../utility/APIRequests';

import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import {authActions, loanActions} from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import {Camera} from "react-camera-pro";
import Webcam from "react-webcam";
import parse from 'html-react-parser';


const Settingsprofile = () => {

    const camera                                                = useRef(null);
    const dispatch                                              = useDispatch();
    const userData                                              = useSelector(userSelect);
    const [mySalaryData, setMySalaryData]                       = useState({})
    const [requestingSalary, setRequestingSalary]                       = useState(false);
    const site_settings = useSelector(allRemoteSettingsSelect)




    const checkValidityData = async () => {
        
        setRequestingSalary(true)

        let apiObj = new APIRequests
        let checkValidity = await apiObj.runGet(`salary/check-validity-only`)

        setRequestingSalary(false)

        setMySalaryData(checkValidity?.data)

    }
    
    useEffect(() => {
        checkValidityData();
    }, [])



    const requestSalaryNow = () => {
        
        if(requestingSalary){ return; }

        setRequestingSalary(true);

        dispatch(authActions.onRefreshSystemData(`salary/request`, 10, {}, response => {
            notify(response.msg, response.code)

            if(response.code == 1){
                
            }
            setRequestingSalary(false);
        }))

    }


    return (
        <ProfileWrapper pageTitle={"Salary"}>


        <div className="card-body">
            <div className="row">
                <div className="col-xxl-12 col-xl-12 col-lg-12">
                    <div className="card">

                        <div className="row g-3">
                            <div className="col-xxl-12 col-xl-12 col-lg-12">


                                <div className="card-header">
                                    <h4 className="card-title">{(site_settings?.salary_settings?.salaryHeadline)}</h4>
                                </div>

                                <div className="card-body">

                                    <div className="row g-3">

                                        <div className="col-xxl-12">
                                            
                                        
                                            <p>
                                                {parse(site_settings?.salary_settings?.salaryText)}
                                            </p>


                                        </div>
                                        
                                    </div>
                                
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>



        <div className="card-body">
            <div className="row">
                <div className="col-xxl-12">
                    
                    {(mySalaryData?.valid ?? '') == 1 && !requestingSalary && <button disabled={requestingSalary} onClick={requestSalaryNow} type="submit" className="btn btn-success waves-effect">
                        
                        Request Salary 

                        <LoadingIcon color={"#fff"} active={requestingSalary} />

                    </button>}

                    {(mySalaryData?.valid ?? 1) != 1 && !requestingSalary && <p>
                        {mySalaryData?.message}
                    </p>}

                    {requestingSalary && <p>
                        Contacting salary server <LoadingIcon color={"#000"} active={requestingSalary} />
                    </p>}



                </div>

            </div>
        </div>












        </ProfileWrapper>
    );
};

export default Settingsprofile;