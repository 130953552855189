import React, {useState} from 'react';
import { Dropdown, } from 'react-bootstrap'
import thumb from '../../images/profile/2.png'
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png'
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from '../../redux/actions';
import {addUrlToPath} from '../../utility/Helper1'
import { userSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';


import Header from './header'
import Sidebar from './sidebar'
import SiteWide from "../../utility/customComponents/SiteWide";
import ChatDelegator from '../../Livechats/ChatDelegator'


const WrapUpDashboard = (props) => {

    const site_settings                                 = useSelector(allRemoteSettingsSelect);

    const [openLivechat, setOpenLivechat] = useState(false)

    const updateOpenry = () => {

        if(openLivechat){
            // window.location.reload()
        }

        setOpenLivechat(el => !el)

    }

    return (
        <SiteWide pageTitle={props?.pageTitle}>

            <Header />

            <Sidebar />

            <div className="content-body" style={{}}>
                <div className="container">
                    <div className="row">
                        {props.children}
                    </div>
                </div>
            </div>
            
           {/* <div>
                <img className="pointer" onClick={updateOpenry} src={require(`../../images/${!openLivechat?'live-chat.png':'close-live-chat.png'}`)} style={{width: 50, position: 'fixed', right: 40, bottom: 100, zIndex: 999999999}} />
            </div>*/}
            

        </SiteWide>
    );
};

export default WrapUpDashboard;