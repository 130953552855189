import React, {useState} from 'react';
import { Dropdown, } from 'react-bootstrap'
import thumb from '../../images/profile/2.png'
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png'
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from '../../redux/actions';
import {addUrlToPath} from '../../utility/Helper1'
import { userSelect, homeScreenStatSelect, allTradeSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';


const Header = () => {

    const dispatch = useDispatch();

    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const userData = useSelector(userSelect);
    const finance = useSelector(homeScreenStatSelect);
    const tradesData = useSelector(allTradeSelect);


    const doLogOutNowNow = () => {
        dispatch(authActions.onLogout())
    }


    const avatarImg = ((userData?.image ?? null) != null)?addUrlToPath(userData?.image):require('../../images/profile/user.png')
    const demoAccount = userData?.demoAccount == 1?'Demo Account':''

    return (
        <>
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="header-content">
                                <div className="header-left">
                                    <div className="rand-logo" style={{marginRight: 12}}>
                                        <Link to={"/"}>
                                            <img style={{height: 30}} src={addUrlToPath(site_settings?.site_settings?.logo)} alt="logo"/>
                                            {/*<span>{site_settings?.site_settings?.sitename}</span>*/}
                                        </Link>
                                    </div>
                                    
                                    <div className="search">

                                        
                                        <div className="input-group text-danger"><strong>{demoAccount}</strong></div>


                                        {/*<form action="#">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search Here" />
                                                <span className="input-group-text"><i className="icofont-search"></i></span>
                                            </div>
                                        </form>*/}
                                    </div>
                                    
                                </div>

                                <div className="header-right">
                                    {/* <div className="dark-light-toggle" onclick="themeToggle()">
                                        <span className="dark"><i className="icofont-moon"></i></span>
                                        <span className="light"><i className="icofont-sun-alt"></i></span>
                                    </div> */}

                                    <div className="notification" alignRight>

                                            <Link className="notify-bell" to="/">
                                                <span><i className="icofont-home"></i></span>
                                            </Link>

                                    </div>

                                    {(site_settings?.faq?.length ?? 0) > 0 && <div className="notification" alignRight>

                                            <Link className="notify-bell" to="/faq">
                                                <span><i className="icofont-info-circle"></i></span>
                                            </Link>

                                    </div>}

                                    <div className="notification" alignRight>

                                            <Link className="notify-bell" to="/settings-activity">
                                                <span><i className="icofont-alarm"></i></span>
                                            </Link>

                                    </div>


                                    <Dropdown className="profile_log" alignRight>
                                        <Dropdown.Toggle>
                                            <span className="thumb"><img src={avatarImg} alt="avatar" width="30" /></span>
                                            <span className="arrow"><i className="icofont-angle-down"></i></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <div className="user-email">
                                                <div className="user">
                                                    <span className="thumb">
                                                        <img src={avatarImg} alt="avatar" width="30" />
                                                    </span>
                                                    <div className="user-info">
                                                        <h5>{userData?.username}</h5>
                                                        <span>{userData?.email}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            <Link to={"settings-profile"} className="dropdown-item">
                                                <i className="icofont-ui-user"></i>Profile
                                            </Link>
                                            <Link to={"wallet"} className="dropdown-item">
                                                <i className="icofont-wallet"></i>Wallet
                                            </Link>
                                            
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <a className="pointer"
                                        style={{marginLeft: 20}}
                                        onClick={doLogOutNowNow}
                                        title="Signout">
                                        <span><i className="icofont-power h2"></i></span>
                                    </a>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Header;