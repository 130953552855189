import React, {useState, useEffect} from "react";
import {Images} from "./index";


function NoData({type, title, subTitle}) {

    return <div style={{display: 'flex', marginTop: 40, textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
        <div className="text-center" style={{marginTop: 40, textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
            <Images height={60} src={require('../../images/no-data.png')} />
            <div style={{marginTop: 30}}>
                <h6 className="prem text-dark text-center">{title ?? `No ${subTitle} information`}</h6>
            </div>
        </div>
    </div>
}

export default NoData;