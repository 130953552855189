import React, {useState, useEffect} from 'react';


import DonutChart from '../../charts/donut'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect, allWithdrawalSelect } from '../../../redux/selectors/index';
import Helper1, {notify, subStr, addUrlToPath, moneyStatus, usefulColors, money} from '../../../utility/Helper1';
import { AiOutlineWallet, AiFillDelete, AiOutlineCopy, AiFillInfoCircle, AiOutlineCloudUpload } from "react-icons/ai";
import NoData from "../../../utility/customComponents/NoData";


const Balance = () => {

    const finance = useSelector(homeScreenStatSelect);
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    

    let totalBalance = 0
    let totalCommission = 0
    let allLabels = []
    let allSeries = []

    finance?.forEach((item, index) => {
        totalBalance+=Number(userData?.demoAccount == 1?item.demoBalance:item.balance)
        totalCommission+=Number(item.commission)
        allLabels = [...allLabels, item.name]
        allSeries = [...allSeries, item.balance]
    })


    return (<div className="card">
                    <div className="card-header">
                        <h4 className="card-title">{userData?.demoAccount == 1?'Demo ':''}Balance</h4>
                    </div>
                    <div className="card-body" style={{}}>
                        <div className="row align-items-center">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                <div className="balance-chart">

                                    {totalBalance > 0 && <>
                                        <DonutChart series={allSeries} label={allLabels} />
                                        <h4>Total {userData?.demoAccount == 1?'Demo ':''} Balance = {totalBalance}</h4>
                                    </>}
                                    {totalBalance == 0 && <NoData title="Empty Wallets" />}
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">

                                <ul className="balance-widget"  style={{height: 380, overflowY: 'auto'}}>
                                    

                                    {finance?.map((item, index) => <li>
                                        <div className="icon-title">
                                            <img style={{width: 30, height: 30}} src={item?.image} alt="img" />
                                            <span style={{marginLeft: 5}}>{item.name}</span>
                                        </div>
                                        <div className="text-right">
                                            <h5>{money(userData?.demoAccount == 1?item.demoBalance:item.balance, item.type)}</h5>
                                            {userData?.demoAccount == 0 && <span>{money(item.commission, item.type)} <AiFillInfoCircle title="Referral commission" /></span>}
                                        </div>
                                    </li>)}

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>);
};

export default Balance;