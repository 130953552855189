import React, {useState} from 'react';
import ProfileWrapper from './ProfileWrapper'
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import Helper1, {notify, subStr, addUrlToPath} from '../../../utility/Helper1';
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import {authActions} from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';


const Settingsprofile = () => {

    const dispatch = useDispatch();
    const userData = useSelector(userSelect);
    const [uploadingAvatar, setUploadingAvatar]     = useState(false);
    const [phoneUpdate, setPhoneUpdate]             = useState(false);
    const [phoneNumber, setPhoneNumber]             = useState(userData?.phone);
    
    const [accountUpdate, setAccountUpdate]         = useState(false);
    const [firstname, setFirstname]                 = useState(userData?.firstname ?? '');
    const [middlename, setMiddlename]                 = useState(userData?.middlename ?? '');
    const [surname, setSurname]                     = useState(userData?.surname ?? '');
    const [DOB, setDOB]                             = useState(userData?.DOB ?? '');
    const [sex, setSex]                             = useState(userData?.sex ?? '');

    const [address, setAddress]                     = useState(userData?.address ?? '');
    const [city, setCity]                     = useState(userData?.city ?? '');
    const [country, setCountry]                     = useState(userData?.countryName ?? '');
    const [postalCode, setPostalCode]                     = useState(userData?.postalCode ?? '');
    const [togglingDemoOrLive, setTogglingDemoOrLive]     = useState(false);

    

    const avatarImg = ((userData?.image ?? null) != null)?addUrlToPath(userData?.image):require('../../../images/profile/user.png')

    const uploadAvatar = async (file) => {

        if(uploadingAvatar){ return;}

        setUploadingAvatar(true)

        dispatch(authActions.onLogin("account/change-avatar", {avatar:file}, response => {
                setUploadingAvatar(false)
            }),
        )
  
    }


    const updatePhoneNumber = async (file) => {

        if(phoneUpdate){ return;}

        setPhoneUpdate(true)

        dispatch(authActions.onLogin("account/update/phone", {phone:phoneNumber}, response => {
                setPhoneUpdate(false)
                notify(response?.msg, response?.code)
            }),
        )
  
    }


    const updateAccountInfo = async (file) => {

        if(accountUpdate){ return;}

        setAccountUpdate(true)

        let detail = {firstname, middlename, surname, DOB, address, city, postalCode, sex}

        dispatch(authActions.onLogin("account/update/info", detail, response => {
                setAccountUpdate(false)
                notify(response?.msg, response?.code)
            }),
        )
  
    }



    const toggleDemoOrLive = (e) => {

        e.preventDefault()

        if(togglingDemoOrLive){ return;}

        setTogglingDemoOrLive(true)

        let detail = {toggle: 1}

        dispatch(authActions.onLogin("account/toggle/demo", detail, response => {
                setTogglingDemoOrLive(false)
                notify(response?.msg, response?.code)
            }),
        )
  
    }





    return (
        <ProfileWrapper pageTitle={"Profile"}>
            
            <div className="card-body">
                <div className="row">

                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">User Profile</h4>
                            </div>
                            <div className="card-body">
                                <form action="#">
                                    <div className="row g-3">
                                        
                                        <div className="col-xxl-12">
                                            <div className="d-flex align-items-center">
                                                <LoadingOverlay
                                                    active={uploadingAvatar}
                                                    spinner
                                                >
                                                    <img className="mr-3 rounded-circle mr-0 mr-sm-3" src={avatarImg} width="55" height="55" alt={userData?.username} />

                                                </LoadingOverlay>

                                                <div className="media-body">
                                                    <h4 className="mb-0">{userData?.username}</h4>
                                                    <p className="mb-0">Max file size is 4MB
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-12">
                                            <div className="form-file">
                                                <input onChange={(e) => uploadAvatar(e.target.files[0])} type="file" className="form-file-input"
                                                    id="customFile"/>
                                                <label className="form-file-label" for="customFile">
                                                    <span className="form-file-text">Choose file...</span>
                                                    <span className="form-file-button">Browse</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-xxl-12">
                                            <button type="button" className="btn btn-success waves-effect">
                                                
                                                Save 

                                                <LoadingIcon color={"#fff"} active={uploadingAvatar} />

                                            </button>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                   




                    <div className="col-xxl-6 col-xl-6 col-lg-6">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Update Phone</h4>
                            </div>
                            <div className="card-body">

                                    <div className="row g-4">

                                        <div className="col-12">
                                            <label className="form-label">Phone Number</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        {userData?.countryPhoneCode}
                                                </span>
                                                </div>
                                                <input onChange={(e) => setPhoneNumber(e.target.value)} type="text" value={phoneNumber} className="form-control"
                                                placeholder="Phone"/>
                                            </div>
                                        </div>

                                        <div className="col-xxl-12">
                                            <button type="submit" onClick={updatePhoneNumber} className="btn btn-success waves-effect">
                                                
                                                Save 

                                                <LoadingIcon color={"#fff"} active={phoneUpdate} />

                                            </button>

                                        </div>

                                    </div>
                            </div>
                        </div>
                    </div>








                    <div className="col-xxl-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Personal Information</h4>
                            </div>
                            <div className="card-body">
                                
                                    <div className="row g-4">
                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <label className="form-label">First Name</label>
                                            <input value={firstname} onChange={e => setFirstname(e.target.value)} type="text" className="form-control"
                                                placeholder="First Name" />
                                        </div>

                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <label className="form-label">Middle Name</label>
                                            <input value={middlename} onChange={e => setMiddlename(e.target.value)} type="text" className="form-control"
                                                placeholder="Middle Name" />
                                        </div>

                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <label className="form-label">Last Name</label>
                                            <input value={surname} onChange={e => setSurname(e.target.value)} type="text" className="form-control"
                                                placeholder="Last Name" />
                                        </div>

                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <label className="form-label">Date of birth (DD/MM/YYYY)</label>
                                            <input type="text" maxLength="10" className="form-control hasDatepicker"
                                                placeholder="DD/MM/YYYY" onChange={e => setDOB(e.target.value)} value={DOB} id="datepicker"
                                                autocomplete="off" />
                                        </div>
                                        

                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <label className="form-label">Sex</label>
                                            <select value={sex} onChange={(e) => setSex(e.target.value)} className="form-select">
                                                <option hidden>Select Gender</option>
                                                <option value="0">Male</option>
                                                <option value="1">Female</option>
                                            </select>
                                        </div>
                                        


                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <label className="form-label">Address</label>
                                            <input value={address} onChange={e => setAddress(e.target.value)} type="text" className="form-control"
                                                placeholder="Address" />
                                        </div>


                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <label className="form-label">City</label>
                                            <input value={city} onChange={e => setCity(e.target.value)} type="text" className="form-control"
                                                placeholder="City" />
                                        </div>

                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <label className="form-label">Postal code</label>
                                            <input value={postalCode} onChange={e => setPostalCode(e.target.value)} type="text" className="form-control"
                                                placeholder="Postal code" />
                                        </div>

                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <label className="form-label">Country</label>
                                            <input disabled value={country} onChange={e => setCountry(e.target.value)} type="text" className="form-control"
                                                placeholder="Country" />
                                        </div>



                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <label className="form-label">Email</label>
                                            <input disabled type="email" className="form-control"
                                                value={userData?.email} />
                                        </div>


                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <label className="form-label">Username</label>
                                            <input disabled type="email" className="form-control"
                                                value={userData?.username} />
                                        </div>

                                        

                                        <div className="col-12">
                                            <button
                                                onClick={updateAccountInfo}
                                                className="btn btn-success pl-5 pr-5 waves-effect">
                                                
                                                Save

                                                <LoadingIcon color={"#fff"} active={accountUpdate} />

                                            </button>
                                        </div>
                                    </div>

                            </div>
                        </div>
                    </div>







                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">{userData?.demoAccount == 0?'Live Account':'Demo Account'}</h4>
                            </div>
                            <div className="card-body">
                                <form action="#">
                                    <div className="col-lg-6">

                                        <div className="form-check form-switch mb-3">
                                            <input checked={userData?.demoAccount == 0} className="form-check-input" type="checkbox" id="s1"
                                                />
                                            <label className="form-check-label" for="s1">Account is currently on <strong className={userData?.demoAccount == 0?'text-info':'text-danger'}>{userData?.demoAccount == 1?'demo trading':'live trading'}</strong></label>
                                        </div>
                                        
                                    </div>

                                    <div className="col-12">

                                        <button 
                                            disabled={togglingDemoOrLive}
                                            onClick={toggleDemoOrLive}
                                            className={`btn btn-${userData?.demoAccount == 0?'danger':'success'} waves-effect`}>

                                            Switch to {userData?.demoAccount == 0?'demo':'live'}

                                            <LoadingIcon color={"#fff"} active={togglingDemoOrLive} />

                                        </button>

                                        
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                                    




                </div>
            </div>

        </ProfileWrapper>
    );
};

export default Settingsprofile;