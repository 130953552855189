import React from 'react';
import { FallingLines } from  'react-loader-spinner'




function LoadingIcon(props) {

    return <span style={{marginLeft: 5}}>
            <FallingLines
              color={props?.color ?? "#29b6f6"}
              width="20"
              visible={props?.active ?? false}
              ariaLabel='falling-lines-loading'
            />
        </span>

}

export default LoadingIcon;

