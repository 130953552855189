import React from 'react';
import { Link } from 'react-router-dom';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import {useSelector} from 'react-redux'

const SubMenu = (props) => {

    const site_settings = useSelector(allRemoteSettingsSelect)


    const allMenu = [
            {
                title: 'Profile',
                url: '/settings-profile',
                show: true
            },
            {
                title: 'Payment Method',
                url: '/settings-payment-method',
                show: true

            },
            {
                title: 'KYC',
                url: '/settings-kyc',
                show: Number(site_settings?.kyc_settings?.showKYCMenuOnUserEnd) == 1

            },
            {
                title: 'Salary',
                url: '/settings-salary',
                show: Number(site_settings?.salary_settings?.useSalary) == 1

            }
        ]
    return (
        <>
            <div className="settings-menu">
                {allMenu?.map((item, index) => {

                    if(!item.show){ return <></>}
                    return <Link to={item.url} className={props?.pageTitle === item?.title?"text-primary":''}>{item?.title}</Link>
                })}
            </div>
        </>
    );
};

export default SubMenu;