import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import {useHistory} from 'react-router-dom';
import APIRequests from '../../../utility/APIRequests';
import Helper1, {notify} from '../../../utility/Helper1';
import { useSelector, useDispatch } from 'react-redux';
import {addUrlToPath} from '../../../utility/Helper1'
import { userSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import {authActions} from '../../../redux/actions';

// import AuthHolder from "./AuthHolder";
import {WebLink, SiteWide, Images} from "../../../utility/customComponents";

const AuthHolder = (props) => {

   
    const site_settings                                 = useSelector(allRemoteSettingsSelect);


    return (
        <SiteWide pageTitle={props.title}>
            <div className="authincation section-padding">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-5 col-md-6">
                            
                            <div className="mini-logo text-center my-4">
                                <a href={site_settings?.site_settings?.homepage_url}>
                                    <img style={{height: 50}} src={addUrlToPath(site_settings?.site_settings?.logo)} alt="logo"/>
                                </a>
                                <h4 className="card-title mt-3">{props.title}</h4>
                                {props?.title2 && <p>{props?.title2}</p>}
                            </div>

                            {props.children}

                        </div>
                    </div>
                </div>
            </div>
        </SiteWide>
    );
};

export default AuthHolder;