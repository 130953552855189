import React, {Component, useState} from "react";
import SiteWide from "../../utility/customComponents/SiteWide";
import { userSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';

import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import WrapUpDashboard from '../layout/WrapUpDashboard'
import Faqo from "react-faq-component";

    

function FAQ(){

    const site_settings = useSelector(allRemoteSettingsSelect);

    const styles = {
        bgColor: 'transparent',
        titleTextColor: "#000",
        rowTitleColor: "#000",
        rowContentColor: '#aeaeb5',
        arrowColor: "#000",
    };

    const config = {
        animate: true,
        // arrowIcon: "V",
        tabFocus: true,
        expandIcon: "+",
        collapseIcon: "-",
    };

    const data = {    
        title: "",
        rows: site_settings?.faq?.map(function(item, index){ return {title: item.question, content: item.answer} })

    };


    return (
        <WrapUpDashboard pageTitle={"FAQ"}>


            <div className="row">
                


                    <div className="col-xxl-12 col-xl-12">
                        <div className="card">
                                
                                <div className="card-header">
                                    <h4 className="card-title">{'FAQ'}</h4>
                                </div>
                                <div className="card-body">
                                    <Faqo
                                        data={data}
                                        styles={styles}
                                        config={config}
                                    />
                                </div>
                        </div>
                    </div>



            </div>

             
        </WrapUpDashboard>
    );

}

export default FAQ;