import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';

const SubMenu = (props) => {

    const site_settings                                 = useSelector(allRemoteSettingsSelect);

    const allMenu = [
            {
                title: 'Loan Application',
                url: '/loan',
                show: true
            },
            {
                title: 'All Loans',
                url: '/loan-online',
                show: true
            }
        ]
    return (
        <>
            <div className="settings-menu">
                {allMenu?.map((item, index) => {
                    
                    if(!item.show){ return <></>}
                   return <Link to={item.url} className={props?.pageTitle === item?.title?"text-primary":''}>{item?.title}</Link>
                })}
            </div>
        </>
    );
};

export default SubMenu;