import React, {useState} from 'react';
import SettingsWrapper from './SettingsWrapper'
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import Helper1, {notify, subStr, addUrlToPath} from '../../../utility/Helper1';
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import {authActions} from '../../../redux/actions';
import LoadingOverlay from 'react-loading-overlay';
import PinAuth from "../../../utility/customComponents/PinAuth";

const ChangePassword = () => {

    const dispatch = useDispatch();
    const userData = useSelector(userSelect);
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [securityObj, setSecurityObj] = useState({})
    const [passwordUpdate, setPasswordUpdate]   = useState(false);
    
    const doPasswordChange = async (e) => {

        if(passwordUpdate){ return; }
        e.preventDefault();

        if(password == ''){
            notify('Please input new password', 0);
            return false;
        }

        if(password != password2){
            notify('Password mismatch. Enter valid passwords', 0);
            return false;
        }

        const data = {password, ...(securityObj ?? {})};

        setPasswordUpdate(true);
        
        dispatch(authActions.onLogin("account/change_password", data, response => {
            
            if(response.code == 1){
                setSecurityObj({});
            }

            notify(response.msg, response.code)
            setPasswordUpdate(false);
        }),
        );

        
    }

    




    return (
        <SettingsWrapper pageTitle={"Change Password"}>
            
            <div className="card-body">
                <div className="row">

                   


                    <div className="col-xxl-12 col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Change Password</h4>
                            </div>
                            <div className="card-body">

                                    <div className="row g-4">

                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <label className="form-label">New password</label>
                                            <div className="ioup">
                                                <input onChange={(e) => setPassword(e.target.value)} type="password" value={password} className="form-control"
                                                placeholder="New password"/>
                                            </div>
                                        </div>

                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <label className="form-label">Confirm New Password</label>
                                            <div className="ioup">
                                                <input onChange={(e) => setPassword2(e.target.value)} type="password" value={password2} className="form-control"
                                                placeholder="Confirm New Password"/>
                                            </div>
                                        </div>

                                        <PinAuth resultingObjMethod={setSecurityObj} userData={userData} />
                                        
                                        <div className="col-xxl-12">
                                            <button disabled={passwordUpdate} type="submit" onClick={doPasswordChange} className="btn btn-success waves-effect">
                                                
                                                Submit 

                                                <LoadingIcon color={"#fff"} active={passwordUpdate} />

                                            </button>

                                        </div>

                                    </div>
                            </div>
                        </div>
                    </div>




                </div>
            </div>

        </SettingsWrapper>
    );
};

export default ChangePassword;