import React, {useState, useEffect} from 'react';
import DonutChart from '../../charts/donut'
import qrImg from '../../../images/qr.svg'



import { Link } from 'react-router-dom';
import {depositActions} from '../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, homeScreenStatSelect, allRemoteSettingsSelect, allWithdrawalSelect } from '../../../redux/selectors/index';
import Helper1, {notify, subStr, addUrlToPath, moneyStatus, usefulColors, money} from '../../../utility/Helper1';
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import copy from 'copy-to-clipboard';
import { AiOutlineWallet, AiFillDelete, AiOutlineCopy, AiFillInfoCircle, AiOutlineCloudUpload } from "react-icons/ai";
import Countdown from 'react-countdown';
import WrapUpDashboard from '../../layout/WrapUpDashboard'
import {withdrawalActions} from '../../../redux/actions';
import QRCode from "react-qr-code";
import PinAuth from "../../../utility/customComponents/PinAuth";
import NoData from "../../../utility/customComponents/NoData";
import Balance from "../Fragments/Balance";




const Wallet = () => {

    const finance = useSelector(homeScreenStatSelect);
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [securityObj, setSecurityObj] = useState({})
    const [currencySelected, setCurrencySelected]   = useState(finance?.[0]);
    const op_se = site_settings.operational_settings
    const loadHistoryData = useSelector(allWithdrawalSelect);


    const [amount, setAmount]   = useState('');
    const [paymentPlaceLoading, setPaymentPlaceLoading]   = useState(false);
    const [withdrawalIsActive, setWithdrawalIsActive]   = useState(false);
    const [selectedWallet, setSelectedWallet]   = useState(0);
    const [withdrawalHistoryLoading, setWithdrawalHistoryLoading]   = useState(false);

    const dispatch = useDispatch();

    const walletSources = [
                            {title: 'Main Balance', column: 'balance', id: 0, useThis: true}, 
                            {title: 'Referral Commission', column: 'commission', id: 1, useThis: Number(op_se?.useReferralBonus) === 1}, 
                            {title: 'Rebate Commission', column: 'rebateCommission', id: 2, balanceKey: 'rebateCommission', useThis: Number(op_se?.useRebateBonus) === 1},
                            {title: 'Deposit Bonus', column: 'depositBonus', id: 3, balanceKey: 'depositBonus', useThis: Number(op_se?.useDepositBonus) === 1},
                            {title: 'Salary Wallet', column: 'salary', id: 4, balanceKey: 'salary', useThis: Number(site_settings?.salary_settings?.useSalary) === 1}
                        ]

    const processWithdrawal = (e) => {
        
        e.preventDefault();

        if(withdrawalIsActive){ return; }

        if((userData?.usdttrc20wallet?.length ?? 0) < '5'){ 
            notify('Please bind your wallet details before withdrawal.');
            return false;
        }

        if((isNaN(amount)) || (amount == 0)){
            // notify('Enter amount', 0);
            return false;
        }


        setWithdrawalIsActive(true);

        let walletChosenColumn = walletSources?.[selectedWallet]?.['column']
        
        dispatch(withdrawalActions.onCreateWithdrawal({amount: amount, ...(securityObj ?? {}), currency: currencySelected?.type, walletAddress: ''}, `withdrawal/new/${walletChosenColumn}`, response => {

            notify(response.msg, response.code)
            if(response.code == 1){
                setAmount('');
            }

            setWithdrawalIsActive(false);

        }))
    }



    const updateCurrencySelected = (typeOfSelected) => {

        let selected = finance?.filter((ite, inde) => ite.type == typeOfSelected)?.[0] ?? []
        setCurrencySelected(selected)

    }

    const changeSelectedWallet = (value) => {
        setSelectedWallet(value)
    }



    


    const styles = {
        tableResponsive: {
          height: 590, // Default height for desktop
        },
    }

    if (window.innerWidth < 768) {
        styles.tableResponsive.height = 'auto'; // Set height to auto for mobile
      }



    const loadAllWithdrawal = () => {

        setWithdrawalHistoryLoading(true)
        dispatch(withdrawalActions.onLoadAllWithdrawals("withdrawal/load", response => {

            if(response.code == 1){
                setWithdrawalHistoryLoading(false)
            }
        }))

    }

    useEffect(() => {
        loadAllWithdrawal()
    }, [])

    return (<WrapUpDashboard pageTitle={"Wallet"}>

        

        <div className="row">


            <div className="col-xxl-12 col-xl-12 col-lg-12">
                <Balance />
            </div>




            <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Wallet Addresses </h4>
                    </div>
                    <div className="card-body">

                        <div className="table-responsive wallet-address-list" ssstyle={{height: 590}}>
                            <table className="table table-striped responsive-table">
                                <thead>
                                    <tr>
                                        <th>Coin Name</th>
                                        <th>Address</th>
                                        <th>QR</th>
                                    </tr>
                                </thead>
                                <tbody>

                                {userData?.bankdetails?.filter(e => e.value != null)?.map((item, detail) => {
                                    if(item?.value?.trim() == ''){ return <></>}

                                    return <tr>
                                        <td>
                                            <div className="coin-name">
                                                <img style={{width: 25}} className="qr-img" src={item.image} alt={item.name} width="40" />
                                                <span>{item?.abbr}</span>
                                            </div>
                                        </td>
                                        <td>{item?.value}</td>
                                        <td>
                                            {item?.value !='' && <QRCode size={25} value={item?.value ?? ''} />}
                                        </td>
                                    </tr>
                                })}
                                    

                                    <tr>
                                        <th>Coin Name</th>
                                        <th>Address</th>
                                        <th>QR</th>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


            <div className={`col-xxl-6 col-xl-6 col-lg-6`}>
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Account Fund </h4>
                    </div>
                    <div className="card-body">
                        
                        <form onSubmit = {processWithdrawal} className="currency_validate trade-form row g-3">


                            <h4>{currencySelected.withdrawal_note_headline}</h4>
                            <p className="mb-0">{currencySelected.withdrawal_note_body}</p>

                            <div className="col-12">
                                <label className="form-label">Wallet</label>
                                <div className="input-group">

                                    <select onChange={e => updateCurrencySelected(e.target.value)} className="form-select">
                                        {finance?.map((item, index) => <>
                                            <option value={item.type}>{item?.abbr}</option>
                                        </>)}
                                    </select>
                                    
                                </div>
                            </div>

                            <div className="col-12">
                                <label className="form-label">Source</label>
                                <div className="input-group">


                                    <select className="form-select" value={selectedWallet} onChange={e => changeSelectedWallet(e.target.value)}>
                                        {walletSources?.map((item, index) => {
                                            
                                            let showBalance = finance?.filter(item => item?.type == currencySelected?.type)?.[0]?.[item?.balanceKey] ?? ''

                                            if(!item.useThis){ return <></>}
                                            return <option value={item?.id}>{item?.title} {showBalance != ''?` - ${money(showBalance, currencySelected?.type)}`:''}</option>
                                        })}

                                    </select>
                                    
                                </div>
                            </div>

                            <div className="col-12">
                                <label className="form-label">Enter Amount</label>
                                <div className="input-group">
                                    
                                    <input autoComplete="new-password" type="text" onChange={(e) => setAmount(e.target.value)} value={amount} name="currency_amount" className="form-control"
                                        placeholder="0.00" />

                                </div>
                            </div>

                            <PinAuth resultingObjMethod={setSecurityObj} userData={userData} />

                            <button disabled={withdrawalIsActive} type="submit" name="submit" className="btn btn-success btn-block">
                                Request
                                <LoadingIcon color={"#fff"} active={withdrawalIsActive} />
                            </button>

                        </form>

                    </div>
                </div>
            </div>

           

            <div className="col-xxl-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Withdrawals </h4>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-striped responsive-table">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Coin Name</th>
                                        <th>Time</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                    </tr>

                                </thead>
                                <tbody>

                                    {loadHistoryData?.map((item, index) => { 

                                    let image = finance?.filter((filitem, index) => filitem.type == item.currency)

                                    return <tr>
                                                <td><span className="info-arrow"><i className="icofont-arrow-up"></i>
                                                    {item.whichWallet}</span>
                                                </td>
                                                <td className="coin-name">
                                                    <img style={{width: 30, height: 30}} src={image?.[0]?.image} alt="img" /> {image?.[0]?.name}
                                                </td>
                                                <td>
                                                    {item?.timestamp}
                                                </td>
                                                <td className="text-danger">
                                                    {money(item?.readOnlyTotalWithdrawn, item?.currency)}
                                                </td>
                                                <td>
                                                    <span className={`text-${usefulColors(item?.status)}`}>{moneyStatus()[item?.status]}</span>
                                                </td>
                                    </tr>})}

                                    {loadHistoryData?.length == 0 && withdrawalHistoryLoading && <tr>
                                        <td colSpan={5} className="text-center">
                                            <LoadingIcon color={"green"} active={withdrawalHistoryLoading} />
                                        </td>
                                    </tr>}

                                </tbody>
                            </table>

                            {(loadHistoryData?.length ?? 0) == 0 && !withdrawalHistoryLoading && <NoData subTitle="withdrawal" />}

                        </div>

                    </div>
                </div>
            </div>



        </div>

    </WrapUpDashboard>);
};

export default Wallet;