import React, {useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { userSelect, allRemoteSettingsSelect } from '../../redux/selectors/index';
import Helper1, {addUrlToPath} from '../../utility/Helper1';
import ChatDelegator from '../../Livechats/ChatDelegator'



function SiteWide(props) {

    const site_settings                                 = useSelector(allRemoteSettingsSelect);

    const texts_content = site_settings?.text_settings?.[0];
    const image_content = site_settings?.image_settings?.[0];
    const descToUse = site_settings?.site_settings?.description
    const titleToUse = props?.pageTitle ?? site_settings?.site_settings?.title
    const siteName = site_settings?.site_settings?.siteName
    const favicon = addUrlToPath(site_settings?.site_settings?.favicon)
    const url = site_settings?.site_settings?.site_url

    return <>
        <Helmet>

            <meta name="theme-color" content={'#fff'} />

            <title>{titleToUse}</title>
            <link rel="icon" type="image/x-icon" href={favicon} />
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            <meta name="description" content={descToUse} />

            <meta property="og:title" content={titleToUse} />
            <meta property="twitter:title" content={titleToUse} />
  
            <meta property="og:site_name" content={siteName} />
            <meta property="twitter:site" content={siteName} />

            <meta property="og:description" content={descToUse} />
            <meta property="twitter:description" content={descToUse} />
  
            <meta property="og:type" content="website" />

            <meta property="og:url" content={url} />

            <meta property="og:image" content={favicon} />
            <meta property="twitter:image" content={favicon} />

            <meta name="twitter:card" content="summary_large_image" />

        </Helmet>

        {props.children}

        {(site_settings?.site_settings?.livechat_sitewide_floating_icon ?? 0) == 1 && 1>2 && <ChatDelegator />}


    </>
   
}

export default SiteWide;


