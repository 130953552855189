import 'react-perfect-scrollbar/dist/css/styles.css';
import '../src/css/style.css'
import Router from './jsx/router'
import { SnackbarProvider, enqueueSnackbar } from 'notistack';



function App() {
  return (
    <>
      <Router />
      <SnackbarProvider 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
    </>
  );
}

export default App;
