import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import APIRequests from '../../../utility/APIRequests';
import Helper1, {notify} from '../../../utility/Helper1';
import { useSelector, useDispatch } from 'react-redux';
import {addUrlToPath} from '../../../utility/Helper1'
import { userSelect, allRemoteSettingsSelect } from '../../../redux/selectors/index';
import {authActions} from '../../../redux/actions';
import {useHistory, useLocation} from 'react-router-dom';

// import AuthHolder from "./AuthHolder";
import {WebLink, SiteWide, Images} from "../../../utility/customComponents";
import AuthHolder from "./AuthHolder";
import {staticCountries} from '../../../utility/countries';
import { RiEyeOffFill, RiEyeFill } from "react-icons/ri";
import LoadingIcon from "../../../utility/customComponents/loader/LoadingIcon";
import { Modal, Button } from 'react-bootstrap'
import parse from 'html-react-parser';
import ReCAPTCHA from "../../../utility/customComponents/ReCAPTCHA";
import NoData from "../../../utility/customComponents/NoData";


let referralToUse = localStorage?.getItem('ref') != 'null'?localStorage?.getItem('ref'):'';


const Signup = () => {

    const dispatch = useDispatch();
    const users = useSelector(userSelect);

    

    const [username, setUsername]                             = useState('');
    const [email, setEmail]                             = useState('');
    const [withdrawalPin, setWithdrawalPin]             = useState('');
    const [showPin, setShowPin]                         = useState(false);
    const [password, setPassword]                       = useState('');
    const [flashCounter, setFlashCounter]               = useState(0);
    const [showPassword, setShowPassword]               = useState(false);
    const [country, setCountry]                         = useState(0); //'156'
    const [phone, setPhone]                             = useState('');
    const [phoneCode, setPhoneCode]                     = useState('...'); //'+234'
    const [referral, setReferrals]                      = useState(referralToUse); //'+234'
    const [agree, setAgree]                             = useState(false);
    const [walletAddress, setWalletAddress]             = useState('');
    const [bank, setBankName]                           = useState('');
    const [accountName, setAccountName]                 = useState('');
    const [accountNumber, setAccountNumber]             = useState('');
    const [depositMethod, setDepositMethod]             = useState(100);



    const [loading, changeLoading]                      = useState(false);
    const [loadingText, setLoadingText]                      = useState("");
    const [notificationMessage, setNotificationMessage] = useState('');
    const [fullPageLoading, setFullPageLoading]         = useState(false);
    const history                                       = useHistory();
    const site_settings                                 = useSelector(allRemoteSettingsSelect);
    const search = useLocation().search;
    const referalLink = new URLSearchParams(search).get("ref");

    const [captcha, setCaptcha]                       = useState(null);

    const captchaHasChanged = (data) => {
        setCaptcha(data)
    }
        


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
        
    useEffect(() => {

        if((referalLink != '') && (referalLink!= null) && (referalLink!='null')){
            localStorage.setItem('ref', referalLink);
            // referralToUse = referalLink;
            setReferrals(referalLink)
        }
    }, [])


    const checkboxHandler = () => {
        // if agree === true, it will be set to false
        // if agree === false, it will be set to true
        setAgree(!agree);
        // Don't miss the exclamation mark
      }

    const doRegister = async (e) => {

        e.preventDefault();

        if((!agree) && ((site_settings?.site_settings?.term ?? '') != '')){
            notify('You must accept the terms and condition to continue', 0);
            return;
        }

        if(!captcha && (Number(site_settings?.site_settings?.use_google_reCaptcha) == 1)){ notify('Invalid captcha response', 0); return; }

        setFullPageLoading(true);
        
        let financeLoad;

        changeLoading(true)
        setLoadingText("Please wait...")

        let payLoad = {captcha, depositMethod, username, email, password, country, phone, withdrawalPin, referral}
        if(depositMethod == 0){
            financeLoad = {bank, accountName, accountNumber}
        }else{
            financeLoad = {walletAddress}
        }
        
        dispatch(authActions.onSignUp("account/register", Object.assign({}, payLoad, financeLoad), response => {

            if(response.code != 0){

                localStorage.removeItem('ref');
                
                // if(response.code == 1){
                    history.replace('/verify-user');
                // }else if(response.code == 2){
                //     history.replace('/personal-information');
                // }
                
            }else{
                notify(response.msg, response.code)
            }

            changeLoading(false)
            setLoadingText("")
            setFullPageLoading(false);
        
        }),
        );


    }


    const changeCountry = (countryId) =>{
        let selectedCountry = staticCountries.filter(e => e.id == countryId) 
        setCountry(countryId);
        let countryPhoneCode = selectedCountry?.[0]?.phonecode;
        setPhoneCode(countryPhoneCode);
        
    }

    
    const openRegBasedOnRefSett = (((site_settings?.operational_settings?.useCompulsoryReferral ?? 0) == 1) && (referral != '') && (referral != null)) || ((site_settings?.operational_settings?.useCompulsoryReferral ?? 0) == 0)

console.log('referral', referral)

    return (
        <AuthHolder title={`Create an account`}>
            <div className="auth-form card">

                <div className="card-body">
                    
                    {openRegBasedOnRefSett && <>

                        <form name="myform" className="signin_validate row g-3">
                            
                            
                            <label className="single-input-wrap">
                                <span className="new-input-label">Username*</span>
                                <input className="auth-input form-control" onChange={e => setUsername(e.target.value)} placeholder="UserName" required type="text" />
                            </label>

                            <label className="single-input-wrap">
                                <span className="new-input-label">Email*</span>
                                <input className="auth-input form-control" onChange={e => setEmail(e.target.value)} placeholder="Email" required type="email" />
                            </label>

                            <label className="single-input-wrap">
                                <span className="new-input-label">Password*</span>
                                <div className="input-group">
                                    <input type={showPassword ? 'text' : 'password'} className="auth-input form-control" onChange={e => setPassword(e.target.value)} required placeholder="Password" />
                                    <div className="input-group-append" style={{ position: 'absolute', right: 2, top: 5, color: '#fff', zIndex: 5, }}>
                                        <span style={{borderWidth: 0}} onClick={() => setShowPassword(!showPassword)} className="input-group-text cursor-pointer input-appended-icon" id="basic-addon1">
                                            {showPassword ? <RiEyeOffFill /> : <RiEyeFill />}
                                        </span>
                                    </div>
                                </div>
                            </label>

                            <label className="single-input-wrap">
                                <span className="new-input-label">Country*</span>
                                <select style={{ height: 45 }} value={country} onChange={e => changeCountry(e.target.value)} required className="auth-input form-control">
                                    <option hidden value="">Select country</option>
                                    {staticCountries.map((item, index) => (
                                        <option key={index} value={item.id}>{item.nicename}</option>
                                    ))}
                                </select>
                            </label>

                            <label className="single-input-wrap">
                                <span className="new-input-label">Phone*</span>
                                <div className="input-group">
                                    <input type='text' style={{ paddingLeft: 80 }} className="auth-input form-control" onChange={e => setPhone(e.target.value)} required placeholder="Phone" style={{ paddingLeft: 90 }} />
                                    <div className="input-group-prepend" style={{ position: 'absolute', width: 70, textAlign: 'center !important', left: 2, top: 3, zIndex: 99999999, borderRight: '1px solid #cecece' }}>
                                        <span style={{ margin: '0 auto', paddingTop: 8, borderWidth: 0 }} onFakedClick={() => setShowPassword(!showPassword)} className="input-group-text cursor-pointer no-background" id="basic-addon1">
                                            {phoneCode}
                                        </span>
                                    </div>
                                </div>
                            </label>

                            {(site_settings?.operational_settings?.request_pin_on_reg ?? 0) == 1 && (
                                <label className="single-input-wrap">
                                    <span className="new-input-label">Account Security Pin*</span>
                                    <div className="input-group">
                                        <input maxLength={site_settings?.operational_settings?.security_pin_length} type={showPin ? site_settings?.operational_settings?.pin_string_format_allowed : 'password'} className="auth-input form-control" value={withdrawalPin} onChange={e => setWithdrawalPin(e.target.value)} required placeholder="Security Pin" />
                                        <div className="input-group-append" style={{ position: 'absolute', right: 2, top: 5, color: '#fff', zIndex: 5 }}>
                                            <span  style={{borderWidth: 0}} onClick={() => setShowPin(!showPin)} className="input-group-text cursor-pointer input-appended-icon" id="basic-addon1">
                                                {showPin ? <RiEyeOffFill /> : <RiEyeFill />}
                                            </span>
                                        </div>
                                    </div>
                                </label>
                            )}

                            <label style={{ display: 'none' }} className="single-input-wrap">
                                <span className="new-input-label">Invited By:</span>
                                <input defaultValue={referral} className="auth-input" onChange={e => setReferrals(e.target.value)} placeholder="Ref. Code Here (Optional)" type="text" required />
                            </label>

                            {(site_settings?.site_settings?.term ?? '') != '' && (
                                <div>
                                    <input type="checkbox" id="agree" onChange={checkboxHandler} /> {" "}
                                    <label htmlFor="agree"> I agree to the <span onClick={handleShow} className="cursor-pointer"><strong className="pointer">terms and conditions</strong></span></label>
                                </div>
                            )}

                            
                            <div className="col-12">
                                <ReCAPTCHA 
                                    onChange={captchaHasChanged}
                                />
                            </div>


                            <div className="text-center">
                                <button onClick={doRegister} type="submit" className="btn btn-primary btn-block">
                                    Sign up
                                    <LoadingIcon color={"#fff"} active={loading} />
                                </button>
                            </div>

                        </form>
                        {(site_settings?.site_settings?.term ?? '') !== '' && <p className="mt-3 mb-0">
                          By registering an account, you acknowledge and agree to our
                          <Link onClick={handleShow} className="text-primary" to={"#"}> Terms of Service</Link>.
                        </p>}

                    </>}

                    {!openRegBasedOnRefSett && <p>

                        <NoData title={"Oops. Registration is by invitation only."} />
                        
                    </p>}

                </div>

            </div>
            



            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Terms and Condition</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    




                    <div className="table-responsive">


                        <div className="container">
                            <div className="row">
                            
                                <div className="col-md-12 text-">
                                  <p className="coin-name">
                                    {parse(site_settings?.site_settings?.term ?? '')}
                                  </p>
                                </div>

                            </div>

                        </div>

                    </div>





                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </AuthHolder>
    );
};

export default Signup;